import { Box, FormControlLabel, Radio, Typography, useMediaQuery } from '@mui/material'
import { Field, FormikProps } from 'formik'
import { RadioGroup } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { SetRequired } from 'type-fest'
import { Nullable } from '../../interfaces/nullable'
import { SliderField } from '../ui-kits/slider-field/slider-field'
import theme, { Theme } from '../../styles/theme'

const deskTypeSelector = ApiSelector('Employee')({
  deskType: true,
  deskTypeRatio: true,
})

type DeskTypeFields = Nullable<ApiInputType<ApiGraphQLTypes['Employee'], typeof deskTypeSelector>>

type Props = SetRequired<Partial<FormikProps<DeskTypeFields>>, 'errors' | 'isSubmitting' | 'values' | 'setFieldValue'>

export const DeskType = ({ values, isSubmitting, setFieldValue, errors }: Props) => {
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()

  return (
    <Box>
      <Field
        component={RadioGroup}
        name='deskType'
        sx={fieldWrapper}
        onChange={(event: Event, value: ApiTypes.DeskType) => {
          setFieldValue('deskType', value)

          if (value !== ApiTypes.DeskType.DUAL_DESK) {
            setFieldValue('deskTypeRatio', 0)
          }
        }}
      >
        <FormControlLabel
          value={ApiTypes.DeskType.PERMANENT}
          control={<Radio disabled={isSubmitting} />}
          label={t('enum.DeskType.PERMANENT')}
          disabled={isSubmitting}
        />
        <FormControlLabel
          value={ApiTypes.DeskType.CONTRACT}
          control={<Radio disabled={isSubmitting} />}
          label={t('enum.DeskType.CONTRACT')}
          disabled={isSubmitting}
        />
        <FormControlLabel
          value={ApiTypes.DeskType.DUAL_DESK}
          control={<Radio disabled={isSubmitting} />}
          label={t('enum.DeskType.DUAL_DESK')}
          disabled={isSubmitting}
        />
      </Field>

      {values.deskType === ApiTypes.DeskType.DUAL_DESK && (
        <Box sx={sliderWrapper}>
          <SliderField
            name={'deskTypeRatio'}
            value={values.deskTypeRatio || 0}
            aria-label='Always visible'
            step={10}
            max={100}
            min={0}
            valueLabelDisplay='on'
            onChange={(event: Event, newValue: number | number[]) => {
              setFieldValue('deskTypeRatio', newValue)
            }}
            sx={dualSlider}
            marks={[
              {
                value: 0,
                label: 'Permanent',
              },
              {
                value: 100,
                label: 'Contract',
              },
            ]}
            helperText={errors?.deskTypeRatio}
          />
          { matchSm &&
            <Box sx={{marginBottom: '8px', width: '57px'}}>
              <Typography variant={'body1'} sx={{fontSize: '16px!important'}}>
                {(values.deskTypeRatio || 0)} / {100 - (values.deskTypeRatio || 0)}
              </Typography>
            </Box>
          }
        </Box>
      )}
    </Box>
  )
}

const sliderWrapper = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]:{
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    justifyContent: 'center',
    '& .slider':{
      width: '73%',
      marginLeft: 0,
      marginRight: '18px',
    },
    '& .MuiSlider-markLabel':{
      marginLeft: '40px!important'
    }
  }
})

const dualSlider = (theme: Theme) =>({
  marginTop: '15px',
})

const fieldWrapper = (theme: Theme) =>({
  display: 'flex',
  flexDirection: 'row',
  // justifyContent: 'space-around',
  justifyContent: 'start',
  [theme.breakpoints.down('sm')]:{
      overflow: 'auto',
      '&::-webkit-scrollbar':{
        height: 0
      },
    flexWrap: 'unset',
    '& label:nth-last-child(1)':{
      margin: 0,
      '& span:nth-last-child(1)': {
        height: '20px',
        overflow: 'hidden'
      }
    }
  },
  [theme.breakpoints.down(410)]:{
    justifyContent: 'unset'
  }
})
