import { Box, Card, Divider, Theme, Typography } from '@mui/material'
import { TypeOfComponent } from '../../pages/employee-profile-edit'
import EditIcon from '@mui/icons-material/Edit'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs';
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter';
import theme from '../../styles/theme'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

interface Props {
  type?: TypeOfComponent
  onChange?: () => void
  managedTeam?: boolean,
  teammatesCount?: number,
  salary?: SalaryFields
}

export const EmployeeEditManage = ({ type, onChange, managedTeam, salary, teammatesCount }: Props) => {
  const { t } = useTranslation();
  const to = useCurrencyFormatter((salary?.to as number) || 0, salary?.currency.id);

  return (
    <Card sx={wrapper}>
      <Box>
        <Box sx={headStyle}>
          <Typography variant={'h6'}>Have you managed a team?</Typography>
          {type === TypeOfComponent.EDIT && (
            <Box onClick={onChange}>
              <EditIcon sx={iconStyle} />
            </Box>
          )}
        </Box>
        <Box>
          <Typography sx={content} >{t(managedTeam ? 'yes' : 'no')}</Typography>
          <Divider />
        </Box>
      </Box>
      {managedTeam &&
        <>
          <Box sx={contentWrapper}>
            <Typography variant={'h6'}>How many teammates have you managed?</Typography>
            <Typography sx={content}>{teammatesCount}</Typography>
            <Divider />
          </Box>
          <Box sx={contentWrapper}>
            <Typography variant={'h6'}>How much does the average person in your team bill?</Typography>
            <Typography sx={schedule}>{salary?.schedule === 'MONTH' ? 'Per Month' : 'Per Year'}</Typography>
            <Typography sx={content}>{to}</Typography>
            <Divider />
          </Box>
        </>
      }
    </Card>
  )
}
const schedule = (theme: Theme) =>({
  color: 'rgba(20, 29, 36, 0.4)',
  fontSize: '16px',
  marginTop: '20px'
})

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  height: 'auto',
  [theme.breakpoints.down('sm')]:{
    '& h2':{
      fontSize: '18px'
    }
  }
})

const contentWrapper = {
  paddingTop: '20px'
}

const headStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start'
}

const iconStyle = {
  color: theme.palette.text.secondary,
  cursor: 'pointer'
}

const content = {
  marginTop: '8px',
  fontWeight: '600'
}
