import { Formik, Form, Field, FieldArray } from 'formik'
import Yup from '../../../validation'
import { FormControl, Divider, Box, Button, FormControlLabel, Radio, Typography, Theme, useMediaQuery, FormGroup, Checkbox } from '@mui/material'
import { SliderField } from '../../ui-kits/slider-field/slider-field'
import { ApiSelector, ApiInputType, ApiGraphQLTypes, ApiTypes } from 'recruticka-frontend-libs'
import { Nullable } from '../../../interfaces/nullable'
import { LoadingButton } from '@mui/lab'
import { RadioGroup, TextField } from 'formik-mui'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { buttonStyle } from '../steps/position-information'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ConsiderPeopleFromOtherSectorRadio } from '../../form-fields/ConsiderPeopleFromOtherSectorRadio'
import { $enum } from 'ts-enum-util'
import Uploader, { assetSelector } from '../../Uploader/Uploader'
import { IMAGES } from '../../../config/uploaderTypes'
import theme from '../../../styles/theme'

const companySelector = ApiSelector('Company')({
  workFromHome: true,
  workFromHomeDays: true,
  considerPeopleFromOtherSector: true,
  inverviewStages: true,
  cultureOfBusiness: true,
  cultureOfBusinessFiles: assetSelector
})

type CompanyFields = ApiInputType<ApiGraphQLTypes['Company'], typeof companySelector>

interface Props extends CompanyFields {
  isSaving: boolean
  currentStep?: number
  backStep: () => void
  onSave: (values: any) => void
}

export const WorkFromHere = ({ workFromHome, workFromHomeDays, considerPeopleFromOtherSector, inverviewStages, cultureOfBusiness, cultureOfBusinessFiles, isSaving, onSave, currentStep, backStep}: Props) => {
  
  const { t } = useTranslation()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const initialValues: Nullable<CompanyFields> = {
    workFromHome: typeof workFromHome === 'boolean' ? workFromHome : null,
    workFromHomeDays: workFromHomeDays || 1,
    considerPeopleFromOtherSector: considerPeopleFromOtherSector || null,
    inverviewStages: inverviewStages || [],
    cultureOfBusiness: cultureOfBusiness || '',
    cultureOfBusinessFiles: cultureOfBusinessFiles || []
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async ({ workFromHome, workFromHomeDays, considerPeopleFromOtherSector, inverviewStages, cultureOfBusiness, cultureOfBusinessFiles }, { setSubmitting }) => {
        if (typeof workFromHome === 'boolean' && workFromHomeDays && considerPeopleFromOtherSector && cultureOfBusiness && cultureOfBusinessFiles) {
          await onSave({ workFromHome, workFromHomeDays, considerPeopleFromOtherSector, inverviewStages, cultureOfBusiness, cultureOfBusinessFiles })
        }
        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, errors, isSubmitting }) => {
        return (
          <Form>
            
            <Box className='stepFieldWrapper' sx={radioOptionStyle}>
              <Typography sx={titleTextStyle}>Do you offer work from home?</Typography>
              <FormControl sx={{ maxWidth: '250px' }}>
                <Field
                  component={RadioGroup}
                  name='workFromHome'
                  sx={fieldWrapper}
                  onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                    setFieldValue('workFromHome', value === 'true')
                    setFieldValue('workFromHomeDays', 1)
                  }}
                >
                  <FormControlLabel
                    checked={values.workFromHome === true}
                    value={true}
                    control={<Radio disabled={isSubmitting} />}
                    label={t('yes')}
                    disabled={isSubmitting}
                  />
                  <FormControlLabel
                    checked={values.workFromHome === false}
                    value={false}
                    control={<Radio disabled={isSubmitting} />}
                    label={t('no')}
                    disabled={isSubmitting}
                  />
                </Field>
              </FormControl>
              {values.workFromHome && (
                <SliderField
                  name={'workFromHomeDays'}
                  value={values.workFromHomeDays || 0}
                  step={1}
                  max={5}
                  min={1}
                  valueLabelDisplay='on'
                  onChange={(event: Event, newValue: number | number[]) => {
                    setFieldValue('workFromHomeDays', newValue)
                  }}
                  marks={[
                    {
                      value: 1,
                      label: '1 day/week',
                    },
                    {
                      value: 5,
                      label: '5 days/week',
                    },
                  ]}
                  helperText={errors?.workFromHomeDays}
                />
              )}
            </Box>
            
            <Divider className='formDivider' />

            <Box className='stepFieldWrapper' sx={radioOptionStyle}>
              <Typography sx={titleTextStyle}>{t('formFields.companyConsiderPeopleFromOtherSector')}</Typography>
              <FormControl sx={{ maxWidth: '350px' }}>
                <ConsiderPeopleFromOtherSectorRadio isSubmitting={isSubmitting} />
              </FormControl>
            </Box>

            <Divider className='formDivider' />
            
            <Box className='stepFieldWrapper' sx={radioOptionStyle}>
              <Typography sx={titleTextStyle}>Select your interview process stages</Typography>
              <Box sx={stagesWrapper}>
                <FormControl  component="fieldset" variant="standard">
                  <FormGroup sx={checkBoxWrap}>
                
                  {$enum(ApiTypes.InterviewStage)
                    .getKeys()
                    .map((item) => {
                      const isSelected = values?.inverviewStages?.includes(item as ApiTypes.InterviewStage)
                      return (
                        <Box sx={checkBox} key={item}>
                          <FormControlLabel
                          sx={{ margin: 0, }}
                            control={
                              <Checkbox 
                                checked={isSelected} 
                                onChange={() => {
                                if (isSelected) {
                                  setFieldValue(
                                    'inverviewStages',
                                    values?.inverviewStages?.filter((elem) => elem !== item),
                                  )
                                } else {
                                  if(values.inverviewStages) {
                                    setFieldValue('inverviewStages', [...values.inverviewStages, item])
                                  } else {
                                    setFieldValue('inverviewStages', [item])
                                  }
                                }
                              }} name={item} />
                            }
                            label={t(`enum.InterviewStage.${item}`)}
                          />
                        </Box>
                      )
                    })}
                  </FormGroup>
                </FormControl>
              </Box>
            </Box>

            <Divider className='formDivider' />

            <Box className='stepFieldWrapper'>
              <Typography sx={[titleTextStyle, {margin: 0,}]}>{t('formFields.companyCultureOfBusinessTitleTitle')}</Typography>
              <FieldArray
                name={'cultureOfBusinessFiles'}
                render={(arrayHelpers) => (
                  <>
                    <Uploader
                      assets={values.cultureOfBusinessFiles}
                      multiple
                      allowedFileTypes={IMAGES}
                      onFileUploaded={(asset) => arrayHelpers.push(asset)}
                      onFileDeleted={(asset) => {
                        const index = (values.cultureOfBusinessFiles || []).findIndex(({ id }) => asset.id === id)
                        arrayHelpers.remove(index)
                      }}
                    />
                  </>
                )}
              />
              <br />
              <Field
                component={TextField}
                name='cultureOfBusiness'
                helperText={errors.cultureOfBusiness}
                placeholder={t('formFields.companyCultureOfBusinessTitleTitle')}
                fullWidth
                sx={{ height: 'auto' }}
                multiline
                rows={matchSm ? 7 : 8}
              />
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  loading={isSaving}
                  sx={buttonStyle}
                  type='submit'
                  size={'medium'}
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={typeof values.workFromHome !== 'boolean' || !values.considerPeopleFromOtherSector || !values.inverviewStages?.length || !values.cultureOfBusiness }
                >
                  {t('saveNext')}
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const validationSchema = Yup.object().shape({
  workFromHome: Yup.bool().required(),
  workFromHomeDays: Yup.number().when('workFromHome', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
  considerPeopleFromOtherSector: Yup.string().required(),
  inverviewStages: Yup.array().min(1),
})


const fieldWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]:{
    justifyContent: 'center'
  }
})

const radioOptionStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0),
  '& .MuiFormControl-root': {
    width: '100%',
    margin: '0 auto 24px',
  },
  [theme.breakpoints.down('sm')]:{
    marginBottom: '66px',
    '& .consider-radio-wrapper':{
      justifyContent: 'center',
      '& .MuiFormControlLabel-root:nth-of-type(3)':{
        marginRight: 0
      }
    }
  }
})

const stagesWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: '650px',
  width: '100%',
  alignSelf: 'center',
  '& .MuiFormControl-root': {
    margin: '0',
  },
  [theme.breakpoints.down('sm')]:{
    marginBottom: '0'
  }
})

const checkBoxWrap = (theme: Theme) => ({
  width: '100%', 
  display: 'flex', 
  flexWrap: 'wrap',
  flexDirection: 'row',
  margin: '0',
})

const checkBox = (theme: Theme) => ({
  width: '50%', 
  margin: 0,
  [theme.breakpoints.down('sm')]:{
    width: '100%', 
  }
})

export const titleTextStyle = (theme: Theme) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  textAlign: 'center',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]:{
    fontSize: '16px'
  }
})
