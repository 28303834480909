import { Formik, Form } from 'formik'
import Yup from '../../../validation'
import { Box, Typography, Button, Divider } from '@mui/material'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { SetOptional } from 'type-fest'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { SalaryHistory } from '../../form-fields/SalaryHistory'
import { CountryAutocomplete } from '../../form-fields/CountryAutocomplete'
import { useDefaultCurrency } from '../../../hooks/useCurrency';
import { salaryWrapper } from './salary-information'
import { buttonStyle } from './position-information'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { DeskType } from '../../form-fields/DeskType'

const salaryHistorySelector = ApiSelector('SalaryHistory')({
  id: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
  history: {
    id: true,
    from: true,
    to: true,
    year: true,
  }
})

type SalaryHistoryFields = ApiInputType<ApiGraphQLTypes['SalaryHistory'], typeof salaryHistorySelector>


const countrySelector = ApiSelector('Country')({
  id: true,
  name: true,
  flagEmoji: true,
})

type CountryFields = ApiInputType<ApiGraphQLTypes['Country'], typeof countrySelector>

const deskTypeSelector = ApiSelector('Employee')({
  deskType: true,
  deskTypeRatio: true,
})

type DeskTypeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof deskTypeSelector>


interface Props extends DeskTypeFields {
  isSaving: boolean,
  currentStep?: number
  backStep: () => void
  onSave: (values: SetOptional<SalaryHistoryFields, 'id'>, countries: CountryFields[], deskType: ApiGraphQLTypes['Employee']['deskType'], deskTypeRatio : ApiGraphQLTypes['Employee']['deskTypeRatio']) => void
  salaryHistory?: SalaryHistoryFields,
  countries?: CountryFields[],
}

export const BillingInformation = ({ onSave, salaryHistory, countries, deskType, deskTypeRatio, isSaving, currentStep, backStep }: Props) => {
  const { t } = useTranslation()
  const defaultCurrency = useDefaultCurrency();
  const initialValues = {
    id: salaryHistory?.id,
    history: salaryHistory?.history || [],
    schedule: salaryHistory?.schedule || ApiTypes.SalaryPeriod.MONTH,
    currency: salaryHistory?.currency || defaultCurrency || null,
    countries: countries || [],
    deskType: deskType || undefined,
    deskTypeRatio: deskTypeRatio || 0,
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values) => {
        const currency = values.currency
        console.log(currency);
        if (currency) {
          return onSave({
            ...values,
            history: values.history?.map((record) => ({ ...record, to: record.from, year: parseInt(String(record.year)) })),
            currency
          }, values.countries, values.deskType, values.deskTypeRatio)
        }
      }}
    >
      {({ errors, values, setFieldValue, isSubmitting }) => {
        return (
          <Form>
            <Box className='stepFieldWrapper'>
              <Box
                sx={salaryWrapper}
              >
                <Typography textAlign={'center'}>
                  <Typography sx={titleTextStyle}>
                    How much are you billing?
                  </Typography>
                  <Typography component={'div'} variant='body2' sx={{ mt: 1 }}>
                    Enter data for the last 2 - 10 years
                  </Typography>
                </Typography>

                <SalaryHistory signupView />
              </Box>
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <CountryAutocomplete name='countries' />
            </Box>
            <Divider className='formDivider' />
            <Box className='stepFieldWrapper'>
              <Typography textAlign={'center'} sx={titleTextStyle}>
                What’s your desk type?
              </Typography>
              <DeskType values={values} setFieldValue={setFieldValue} errors={errors} isSubmitting={isSubmitting} />
            </Box>

            <Box className='stepFooter'>
              <Box>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  variant={'outlined'}
                  size={'medium'}
                  sx={buttonStyle}
                  disabled={currentStep === 0}
                  onClick={backStep}
                >
                  {t('back')}
                </Button>
              </Box>
              <Box>
                <LoadingButton
                endIcon={<ArrowForwardIosIcon />}
                variant='contained'
                loading={isSaving}
                sx={buttonStyle}
                type='submit'
                size={'medium'}
                disabled={!values.history?.length || !values.countries.length || !values.deskType}
              >
                   {t('saveNext')}
              </LoadingButton>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const titleTextStyle = () => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
})

const validationSchema = Yup.object().shape({
  schedule: Yup.string().required(),
  currency: Yup.object().nullable().required(),
  history: Yup.array(Yup.object({
    year: Yup.number().required(),
    from: Yup.number().required().positive(),
    // to: Yup.number().moreThan(Yup.ref('from')).required().positive(),
  })).optional()
})
