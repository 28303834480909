import { Box, Card, Divider, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TypeOfComponent } from '../../pages/employee-profile-edit'
import { ApiSelector, ApiInputType, ApiGraphQLTypes } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'

const employeeSelector = ApiSelector('Employee')({
  canBringExistingClients: true,
  clientIndustries: true,
})

type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>

interface Props extends Partial<EmployeeFields> {
  onChange?: () => void
  type?: TypeOfComponent
}

export const EmployeeEditClients = ({ onChange, type, canBringExistingClients, clientIndustries }: Props) => {
  const { t } = useTranslation()

  return (
    <Card sx={wrapper}>
      <Box sx={headStyle}>
        <Typography variant={'h6'}>My Clients</Typography>
        {type === TypeOfComponent.EDIT && (
          <Box onClick={onChange}>
            <EditIcon sx={iconStyle} />
          </Box>
        )}
      </Box>
      {clientIndustries && (
        <Box sx={infoStyle}>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            In which industries are your clients?
          </Typography>
          <Typography variant={'body1'}>{clientIndustries}</Typography>
          <Divider />
        </Box>
      )}
      {typeof canBringExistingClients === 'boolean' && (
        <Box sx={infoStyle}>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            Can you bring your existing clients to your next role?
          </Typography>
          <Typography variant={'body1'} sx={typographyStyle}>
            {canBringExistingClients ? t('yes') : t('no')}
          </Typography>
          <Divider />
        </Box>
      )}
    </Card>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  height: 'auto',
  [theme.breakpoints.down('sm')]:{
    '& h2':{
      fontSize: '18px'
    },
    '& p':{
      fontSize: '12px'
    }
  }
})

const headStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const infoStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const typographyStyle = (theme: Theme) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})
