import React, { useEffect, useState, useRef } from 'react'
import { 
  Card, 
  Theme, 
  Box, 
  useMediaQuery,
  Typography,
  Tabs, 
  Tab,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider
} from '@mui/material'
import { Main, Content } from '../components/common-files/common-styles'
import TempWrapperNew from '../components/tempWrapperNew'
import { authMutation, useAuthQueryRequest } from '../services/api'
import { ApiSelector, ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { useQueryClient } from 'react-query'
import { SaveEmployeeValues, useUpdateEmployee } from '../hooks/useEmployee'
import { assetSelector, Asset } from '../components/Uploader/Uploader'
import { useProfile } from '../hooks/useProfile'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'


import { UploadCv } from '../components/not-cms/upload-cv'
import { CareerNarrative } from '../components/not-cms/employee-edit-modal/career-narrative'
import { BillingComponent } from '../components/not-cms/employee-edit-modal/billing-component'
// import { LanguageComponent } from '../components/not-cms/employee-edit-modal/language-component'
import { EmployeePreferences } from '../components/not-cms/employee-edit-modal/employee-preferences'
import { SpecialiseInComponent } from '../components/not-cms/employee-edit-modal/specialise-in-component'
import { ClientsComponent } from '../components/not-cms/employee-edit-modal/clients-component'
import { EmployeeManage } from '../components/not-cms/employee-edit-modal/employee-manage'
import { HideFromModal } from '../components/not-cms/employee-edit-modal/hide-from-modal'
import { ProfileStrength } from '../components/not-cms/profile-strength'
import theme from '../styles/theme'

// Icon
import { CareerIcon } from '../components/icons/careerIcon'
import { IdentyCard } from '../components/icons/identyCard'
import { MedalIcon } from '../components/icons/medalIcon'
import { BillingIcon } from '../components/icons/billingIcon'
import { CvIcon } from '../components/icons/cvIcon'
import { Candidates } from '../components/icons/candidates'
import { HidePrivacyIcon } from '../components/icons/hidePrivacyIcon'
import { ClientGroupIcon } from '../components/icons/clientGroupIcon'

import {PageTitle} from '../components/pageTitle'
import { employeeSelector } from '../pages/employee-profile-edit'

import SidebarInternalCandidate from '../components/SidebarInternalCandidate'

import AlertDialog from '../components/not-cms/ui-kits/alert-dialog/alert-dialog'

import Alert from '@mui/material/Alert';


export enum TypeOfComponent {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

enum BlockNames {
  CAREER_NARRATIVE = 'CAREER_NARRATIVE',
  WORK_EXPERIENCE = 'WORK_EXPERIENCE',
  EDUCATION = 'EDUCATION',
  BILLING = 'BILLING',
  LANGUAGES = 'LANGUAGES',
  PREFERENCES = 'PREFERENCES',
  SPECIALISE_IN = 'SPECIALISE_IN',
  CLIENTS = 'CLIENTS',
  HEADER = 'HEADER',
  HIDE_ME = 'HIDE_ME',
  MANAGING = 'MANAGING'
}

const profileHideMeSelector = ApiSelector('Profile')({
  id: true,
  companies: {
    profileId: true,
    name: true,
    logo: {
      url: true,
    },
    isMain: true,
    sisterCompanies: {
      profileId: true,
      name: true,
      isMain: true,
      status: true,
    }
  }
})

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tab-${index}`,
  };
}


interface IAlert {
  isOpen: boolean
  id?: string
}

const initialAlert: IAlert = {
  isOpen: false,
  id: undefined
}

export type ProfileHideMeFields = ApiInputType<ApiGraphQLTypes['Profile'], typeof profileHideMeSelector>

const CandidateProfileSetup = () => {

  const profile = useRecoilValue(profileState)
  const updateEmployeeMutation = useUpdateEmployee(employeeSelector)  
  const queryClient = useQueryClient()
  const { refetch: refetchProfile } = useProfile()
  
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [alert, setAlert] = useState<IAlert>(initialAlert)

  const [value, setValue] = React.useState(0);

  const handletabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    isRefetching: isEmployeeDataRefetching
  } = useAuthQueryRequest(
    'employee',
    {
      getEmployee: [{ id: profile?.employee?.id as string }, employeeSelector]
    },
    {
      enabled: profile?.employee?.id !== undefined
    }
  )

  const saveEmployee = async (values: Partial<SaveEmployeeValues>) => {
    if (employeeData?.getEmployee) {
      const data = await updateEmployeeMutation.mutateAsync({
        values: {
          id: employeeData.getEmployee.id,
          ...values,
        },
        employeeData: employeeData.getEmployee
      })

      if (data) {
        queryClient.setQueryData('employee', {
          getEmployee: data,
        })
      }
    }

    await refetchProfile()

  }

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0)


  const handleSideDropDown = (index: number) => {
    setCurrentMenuIndex(index);
    srollToElement(index);
  };

  function srollToElement(index : number) {
    const offset = -100; 
    const element = sectionRefs.current[index];
    if (element) {
      const offsetPosition = element.offsetTop + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  const updateEmployeeAfterHideMeSaved = (
    hiddenFromProfile: ProfileHideMeFields[],
    visibleForProfile: ProfileHideMeFields[],
    hideFromEveryone: boolean,
    displayName?: string | undefined
  ) => {

    if (employeeData?.getEmployee) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...employeeData.getEmployee,
          ...{
            profile: {
              ...employeeData.getEmployee.profile,
              visibleForProfile,
              hiddenFromProfile,
              hideFromEveryone,
              displayName,
            },
          },
        },
      })
    }

  }

  if (isEmployeeDataLoading || isEmployeeDataRefetching || !employeeData?.getEmployee) {
    return <CircularProgress />
  }

  const onCvUploaded = async (file: Asset) => {
    if (!profile?.employee?.id) {
      return
    }

    const { updateEmployee } = await authMutation({
      updateEmployee: [
        {
          id: profile.employee.id,
          input: {
            cv: {
              connect: {
                id: file.id,
              },
            },
          },
        },
        {
          id: true,
          cv: assetSelector,
        },
      ],
    })

    if (employeeData?.getEmployee && updateEmployee?.cv) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...employeeData.getEmployee,
          cv: updateEmployee.cv,
        },
      })
    }
  }

  const onCvDeleted = async () => {
    if (!profile?.employee?.id) {
      return
    }

    if (employeeData?.getEmployee) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...employeeData.getEmployee,
          cv: null,
        },
      })
    }
  }

  return (
    <TempWrapperNew>
      <Main sx={wrapper}>
        <Content sx={contentWrapper}>
          <PageTitle title='Profile'></PageTitle>
          <Box sx={profileStrengthBox}>
            <ProfileStrength value={profile?.employee?.strength ? profile?.employee?.strength : 0} showBar={true} isClient={false}  />
          </Box>
            <Box sx={tabWrapper}>
                { !matchSm &&
                  <SidebarInternalCandidate handleSideDropDown={handleSideDropDown} currentMenuIndex={currentMenuIndex} />

                  // <Box sx={tabListBox}>
                  //   <Box sx={titleBox}>
                  //     <Typography variant='h6' component='h6'>
                  //       Candidates
                  //     </Typography>
                  //   </Box>
                  //   <Tabs
                  //     orientation='vertical'
                  //     variant='scrollable'
                  //     value={value}
                  //     onChange={handletabChange}
                  //     aria-label='Vertical tabs example'
                  //     sx={tabBox} >
                  //       <Tab icon={<CareerIcon />} iconPosition='start' label='Career narrative' {...a11yProps(0)} />
                  //       <Tab icon={<IdentyCard/>} label='Additional information' {...a11yProps(1)} />
                  //       <Tab icon={<MedalIcon/>} label='Specialises in' {...a11yProps(2)} onClick={(e) => handletabChange(e, 2)} />
                  //       <Tab icon={<BillingIcon/>} label='Billings' {...a11yProps(3)} onClick={(e) => handletabChange(e, 3)} />
                  //       <Tab icon={<CvIcon/>} label='CV' {...a11yProps(4)} onClick={(e) => handletabChange(e, 4)} />
                  //       <Tab icon={<Candidates />} label='Have you managed a team?' {...a11yProps(4)} onClick={(e) => handletabChange(e, 5)} />
                  //       <Tab icon={<HidePrivacyIcon />} label='Hide me from' {...a11yProps(4)} onClick={(e) => handletabChange(e, 6)} />
                  //       <Tab icon={<ClientGroupIcon />} label='My clients' {...a11yProps(4)} onClick={(e) => handletabChange(e, 7)} />
                  //   </Tabs>
                  // </Box>
                }

                <Box sx={tabContentBox}>

                  <Alert severity="info" sx={{ mb: 2, }}>Make your profile stand out by sharing detailed billing information from as many previous years as possible. This will increase your chances of catching the eye of hiring managers and getting more interviews.</Alert>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[0] = ref as HTMLDivElement)}>
                      <CareerNarrative
                        recruitRoles={employeeData.getEmployee.recruitRoles}
                        reasonForLeavingRecentEmployer={employeeData.getEmployee.reasonForLeavingRecentEmployer}
                        jobTypes={employeeData.getEmployee.jobTypes}
                        jobCriterias={employeeData.getEmployee.jobCriterias}
                        onSave={saveEmployee}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[1] = ref as HTMLDivElement)}>
                      <EmployeePreferences
                        preferSalary={employeeData.getEmployee?.preferSalary}
                        deskType={employeeData.getEmployee?.deskType}
                        deskTypeRatio={employeeData.getEmployee?.deskTypeRatio}
                        recruitCountries={employeeData.getEmployee?.recruitCountries}
                        onSave={(values) => {
                          saveEmployee({ ...values })
                        }}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[2] = ref as HTMLDivElement)}>
                    <SpecialiseInComponent
                        specializations={employeeData.getEmployee?.specializations || []}
                        onSave={({ specializations }) => saveEmployee({ specializations })}
                        isSaves={updateEmployeeMutation.isLoading}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[3] = ref as HTMLDivElement)}>
                      <BillingComponent
                          salaryHistory={employeeData.getEmployee.salaryHistory}
                          onSave={(salaryHistory) => saveEmployee({ salaryHistory })}
                        />
                  </Card>

                  <Card sx={[cardWraper, {paddingBottom: '32px !important',}]} ref={(ref) => (sectionRefs.current[4] = ref as HTMLDivElement)}>
                      <UploadCv
                        cv={employeeData.getEmployee.cv}
                        type={TypeOfComponent.EDIT}
                        onCvUploaded={onCvUploaded}
                        onCvDeleted={onCvDeleted}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[5] = ref as HTMLDivElement)}>
                      <EmployeeManage
                        onSave={async (values) => {
                          await saveEmployee({ ...values })
                        }}
                        managedTeam={employeeData.getEmployee.managedTeam}
                        teammatesCount={employeeData.getEmployee.teammatesCount}
                        teamAvgSalary={employeeData.getEmployee.teamAvgSalary}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[6] = ref as HTMLDivElement)}>
                    <HideFromModal
                        hiddenFromProfile={employeeData.getEmployee.profile.hiddenFromProfile || []}
                        visibleForProfile={employeeData.getEmployee.profile.visibleForProfile || []}
                        hideFromEveryone={employeeData.getEmployee.profile.hideFromEveryone}
                        onSave={updateEmployeeAfterHideMeSaved}
                        nickName={employeeData.getEmployee.profile.displayName}
                      />
                  </Card>

                  <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[7] = ref as HTMLDivElement)}>
                    <ClientsComponent {...employeeData.getEmployee} onSave={saveEmployee} />
                  </Card>


                  {/* <Card>
                      <>
                        <TabPanel value={value} index={0}>
                          <CareerNarrative
                            recruitRoles={employeeData.getEmployee.recruitRoles}
                            reasonForLeavingRecentEmployer={employeeData.getEmployee.reasonForLeavingRecentEmployer}
                            jobTypes={employeeData.getEmployee.jobTypes}
                            jobCriterias={employeeData.getEmployee.jobCriterias}
                            onSave={saveEmployee}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                          <EmployeePreferences
                            preferSalary={employeeData.getEmployee?.preferSalary}
                            deskType={employeeData.getEmployee?.deskType}
                            deskTypeRatio={employeeData.getEmployee?.deskTypeRatio}
                            recruitCountries={employeeData.getEmployee?.recruitCountries}
                            onSave={(values) => {
                              saveEmployee({ ...values })
                            }}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <SpecialiseInComponent
                              specializations={employeeData.getEmployee?.specializations || []}
                              onSave={({ specializations }) => saveEmployee({ specializations })}
                              isSaves={updateEmployeeMutation.isLoading}
                            />
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <BillingComponent
                              salaryHistory={employeeData.getEmployee.salaryHistory}
                              onSave={(salaryHistory) => saveEmployee({ salaryHistory })}
                            />
                        </TabPanel>
                        
                        <TabPanel value={value} index={4}>
                          <UploadCv
                            cv={employeeData.getEmployee.cv}
                            type={TypeOfComponent.EDIT}
                            onCvUploaded={onCvUploaded}
                            onCvDeleted={onCvDeleted}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={5}>
                          <EmployeeManage
                            onSave={async (values) => {
                              await saveEmployee({ ...values })
                            }}
                            managedTeam={employeeData.getEmployee.managedTeam}
                            teammatesCount={employeeData.getEmployee.teammatesCount}
                            teamAvgSalary={employeeData.getEmployee.teamAvgSalary}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={6}>
                          <HideFromModal
                            hiddenFromProfile={employeeData.getEmployee.profile.hiddenFromProfile || []}
                            visibleForProfile={employeeData.getEmployee.profile.visibleForProfile || []}
                            hideFromEveryone={employeeData.getEmployee.profile.hideFromEveryone}
                            onSave={updateEmployeeAfterHideMeSaved}
                            nickName={employeeData.getEmployee.profile.displayName}
                          />
                        </TabPanel>

                        <TabPanel value={value} index={7}>
                          <ClientsComponent {...employeeData.getEmployee} onSave={saveEmployee} />
                        </TabPanel>
                      </>
                  </Card> */}
                </Box>
              </Box>
        </Content>
      </Main>
    </TempWrapperNew>
  )
}

const wrapper = (theme: Theme) => ({
  paddingTop: '0',
  paddingBottom: '0',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('xs')]:{
    '&': {
      '& .MuiBox-root': {
        '& .MuiBox-root.MuiGrid-item': {
          width: '100%',
        }
      },
    },
  }
})

const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  marginBottom: '0',
  // gap: '32px',
  [theme.breakpoints.down('sm')]: {
    // gap: theme.spacing(2.5),
    // marginBottom: theme.spacing(6),
  },
})


const menuList = (theme: Theme) => ({
  '& .MuiListItemIcon-root': {
    minWidth: '32px',
  },
  '& .MuiListItemButton-root': {
    paddingTop: '6px',
    paddingBottom: '6px',
    '&.Mui-selected': {
      backgroundColor: '#5c67ea !important',
      '& .MuiListItemIcon-root': {
        '& svg path': {
          fill: '#fff !important',
        }
      },  
      '& .MuiTypography-body1': {
        color: '#fff !important',
      },
      '&:hover': {

      }
    }
  },
  [theme.breakpoints.down(1250)]: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    }
  }
})


const tabWrapper = (theme: Theme) => ({
  display: 'flex', 
  alignItems: 'start',
  height: '100%',
  flexDriection: 'row',
  gap: '24px',
 })

 const tabListBox = (theme: Theme) => ({
   width: '18%',
   backgroundColor: '#fff',
   borderRadius: theme.spacing(.5),
   position: 'sticky',
   top: '96px',
   overflow: 'hidden',
   [theme.breakpoints.down(1250)]: {
     width: '25%',
   }
 })
 
 const titleBox = (theme: Theme) => ({
   backgroundColor: '#E0E0E0',
   padding: theme.spacing(1, 2),
 })
 
 const tabBox = (theme: Theme) => ({
  backgroundColor: '#fff',
  textAlign: 'left',
  color: theme.palette.text.primary,
  '& span.MuiTabs-indicator': {
    right: 'auto !important',
    left: '0 !important',
    display: 'none',
  },
  '& .MuiTab-root': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '6px',
    textTransform: 'unset',
    fontSize: '14px',
    fontWeight: '400',
    minHeight: '48px',
    borderBottom: '1px solid #F1F1F5',
    color: theme.palette.text.primary,
    textAlign: 'left',
    '& svg': {
      width: '30px',
    }, 
    '&.Mui-selected': {
      backgroundColor: 'rgb(30 50 150 / 8%)',
      '& .MuiTab-root': {
        color: theme.palette.text.primary,
      }
      // '& svg path': {
      //   fill: theme.palette.primary.main,
      // }
    },
    '&:last-of-type': {
      borderColor: 'transparent',
    }
  }
})

const tabContentBox = (theme: Theme) => ({
  width: '82%',
  // height: '100%',
  // position: 'relative',
  '& > .MuiCard-root': {
    paddingBottom: theme.spacing(18),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& > .MuiCard-root': {
      paddingBottom: theme.spacing(3),
    }
  },
})

const profileStrengthBox = (theme: Theme) => ({
  mt: theme.spacing(1),
  width: '100%',
  '& .infoStyle': {
    textAlign: 'right',
  },
  [theme.breakpoints.down('md')]: {
    mt: theme.spacing(2),
    '& .infoStyle': {
      textAlign: 'left',
    },
    '& .progressWrapper': {
      '& .MuiTypography-h2': {
        fontSize: '18px',
        lineHeight: '20px',
      }
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .progressWrapper': {
      '& .MuiTypography-h2': {
        fontSize: '16px',
      }
    },
  }
})

const cardWraper = (theme: Theme) => ({
  marginBottom: 6,
  // paddingBottom: '32px !important',
  // height: 'auto',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 3,
  }
})



export default CandidateProfileSetup
