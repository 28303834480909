import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import ChatRightSide from './chat-components/chat-right-side'
import ChatLeftSide from './chat-components/chat-left-side'
import theme from '../../styles/theme'
import { Main, Content } from '../common-files/common-styles'
import { Conversation, lastMessageSelector } from '../../hooks/useConversations'
import { useRecoilValue } from 'recoil'
import { profileState } from '../../state/atoms/profileState'
import { useSearchParams } from 'react-router-dom'
import { chatWindowState } from '../../state/atoms/chatState'
import { queryClient } from '../../react-query.client'
import TempWrapperNew from '../../components/tempWrapperNew'
import { SystemStyleObject } from '@mui/system'
import NoConversations from '../../images/svg-files/no-conversations.svg'
import { wsChain } from '../../services/api'
import { Message, messagesSelector } from '../../hooks/useMessages'

// PageTitle
import {PageTitle} from '../../components/pageTitle'

export enum ChatVariant {
  CHAT_MAIN,
  CHAT_WIDGET,
  CHAT_LIST,
}


interface Props {
  variant?: ChatVariant
  conversations?: Conversation
}

interface PageChatStyle {
  wrapper?: object
  chatWrapper: object
  styleMainLeftSide: object
  rightSide?: number
  variantRightSide: ChatVariant
  styleRightSide: object
  chatWrapperBox: object
  styleMainLeftSideMobile?: object
  styleMainLeftSideMobileClose?: object
  layoutWrapper?: SystemStyleObject
}

export const ChatBox: FC<Props> = ({ variant, conversations }) => {
  const [conversation, setConversation] = useState<Conversation>()
  const [mobileChat, setMobileChat] = useState<boolean>(false)
  const profile = useRecoilValue(profileState)
  const [profileConversations, setProfileConversations] = useState([])
  const [searchParams] = useSearchParams()
  const cId = searchParams.get('conversation_id')
  const chatState = useRecoilValue(chatWindowState)
  const firstUpdate = useRef(true)
  const {
    chatWrapper,
    styleMainLeftSide,
    styleRightSide,
    variantRightSide,
    chatWrapperBox,
    wrapper,
    layoutWrapper,
    styleMainLeftSideMobile,
    styleMainLeftSideMobileClose
  } = useMemo<PageChatStyle>(() => {
    if (variant === ChatVariant.CHAT_WIDGET) {
      return widgetChat(theme)
    }
    return fullChatPage(theme)
  }, [variant])

  const goToChat = useCallback((conversation: Conversation) => {
    if (conversation?.id) {
      setConversation(conversation)
      setMobileChat(true)
    }
  }, [])

  useEffect(() => {
    if (variant === ChatVariant.CHAT_WIDGET) {
      setConversation(conversations)
    }
  }, [variant, conversations?.id])

  useEffect(() => {
    if (cId as string && chatState.conversationId) {
      const conversation: { getProfileConversation: Conversation } | undefined = queryClient.getQueryData(['conversations', cId])
      if (conversation) {
        goToChat(conversation.getProfileConversation as Conversation)
      }
    }
  }, [cId, chatState.conversationId])

  useEffect(() => {

        const webSocket = wsChain('subscription')({ messageAdded: {
            ...messagesSelector,
            replyedTo: {
              ...messagesSelector.replyedTo,
              author: {
                ...messagesSelector.replyedTo.author,
                profile: {
                  ...messagesSelector.replyedTo.author.profile,
                  isHidden: [
                    { profileId: profile.id },
                    true
                  ]
                }
              }
            },
            author: {
              ...messagesSelector.author,
              profile: {
                ...messagesSelector.author.profile,
                isHidden: [
                  { profileId: profile.id },
                  true
                ]
              }
            }
          } })
        webSocket.on(async ({ messageAdded } : { messageAdded : any }) => {

          const cacheDataConversations = queryClient.getQueryData<{
            pages: {
              getProfileConversations: Conversation[]
            }[]
          }>(['conversations', profile.id, null])

          if (cacheDataConversations?.pages.map(pages => {
            return pages.getProfileConversations.find(el => el.lastMessage?.conversationId === messageAdded.conversationId)
          })){
            const conv = cacheDataConversations?.pages.map(pages => {
              return pages.getProfileConversations.map(item => {
                if (item.lastMessage?.conversationId === messageAdded.conversationId) {
                  return { ...item, lastMessage: { ...messageAdded } }
                } else {
                  return item
                }
              })
            })

            const newConversationsArray = conv?.reduce((acc: any, conversation, index) => {
              const getProfileConversations = [...conversation]
              return [...acc, { getProfileConversations }]
            }, [])

            queryClient.setQueryData(['conversations', profile.id, null], ({ pageParams }) => ({
              pages: newConversationsArray,
              pageParams: pageParams
            }))
          } else {
            return await queryClient.refetchQueries()
          }

          const cacheData = queryClient.getQueryData<{
            pages: {
              getMessages: Message[]
            }[]
          }>(['messages', messageAdded.conversationId])

          const messages = cacheData?.pages
          if (messages?.length){
            const newArray = messages?.map((messages, idx) => {
              if (idx === 0) {
                messages.getMessages.unshift(messageAdded)
              }
              return messages
            })
            queryClient.setQueryData(['messages', messageAdded.conversationId], ({ pageParams }) => ({
              pages: newArray,
              pageParams: pageParams
            }))
          } else {
           await queryClient.invalidateQueries()
          }


      })
      return () => webSocket?.ws?.close()

  }, [])

  useEffect(() => {

    const webSocket = wsChain('subscription')({ messageDeleted: true })
    webSocket.on(async ({ messageDeleted }) => {
      if (messageDeleted) {
        return await queryClient.refetchQueries()
      }
    })

    return () => webSocket?.ws?.close()

  }, [])

  useEffect(() => {

    const webSocket = wsChain('subscription')({
      messageUpdated: {
        conversationId: true,
        id: true,
        content: true
      }
    })
    webSocket.on(async ({ messageUpdated }) => {

      const cacheDataConversations = queryClient.getQueryData<{
        pages: {
          getProfileConversations: Conversation[]
        }[]
      }>(['conversations', profile.id, null])

      if (cacheDataConversations?.pages.map(pages => {
        return pages.getProfileConversations.find(el => el.lastMessage?.id === messageUpdated.id)
      })) {
        const conv = cacheDataConversations?.pages.map(pages => {
          return pages.getProfileConversations.map(item => {
            if (item.lastMessage?.id === messageUpdated.id) {
              return { ...item, lastMessage: { ...item.lastMessage, content: messageUpdated.content } }
            } else {
              return item
            }
          })
        })

        const newConversationsArray = conv?.reduce((acc: any, conversation, index) => {
          const getProfileConversations = [...conversation]
          return [...acc, { getProfileConversations }]
        }, [])

        queryClient.setQueryData(['conversations', profile.id, null], ({ pageParams }) => ({
          pages: newConversationsArray,
          pageParams: pageParams
        }))
      }

      const cacheData = queryClient.getQueryData<{
        pages: {
          getMessages: Message[]
        }[]
      }>(['messages', messageUpdated.conversationId])

      if (cacheData?.pages && cacheData?.pages.map(page => page.getMessages.length)) {
        const newArray = cacheData?.pages?.map(page => {
          return page.getMessages.map((message, idx) => {
            if (message.id === messageUpdated.id) {
              return { ...message, content: messageUpdated.content }
            } else {
              return message
            }
          })
        })
        const newPagesArray = newArray?.reduce((acc: any, messages, index) => {
          const getMessages = [...messages]
          return [...acc, { getMessages }]
        }, [])

        queryClient.setQueryData(['messages', messageUpdated.conversationId], ({ pageParams }) => ({
          pages: newPagesArray,
          pageParams: pageParams
        }))
      }
    })

    return () => webSocket?.ws?.close()

  },[])


  return (
    <Box sx={layoutWrapper}>
      <TempWrapperNew>
        <Content sx={contentWrapper}>
          {/* <PageTitle title='Messages'/> */}
          <Box sx={titleWrapper}>
            <Typography variant={'h4'}>{'Messages'}</Typography> 
          </Box>
          <Box sx={{ ...chatWrapper, backgroundColor: '#ffffff' }}>
            <Box
              height={'100%'}
              sx={
                mobileChat ?
                  { ...chatWrapperBox, ...styleMainLeftSideMobile }
                  :
                  { ...chatWrapperBox, ...styleMainLeftSideMobileClose }
              }
            >
              <Box sx={styleMainLeftSide}>
                <ChatLeftSide
                  profileId={profile.id}
                  onClick={goToChat}
                  setProfileConversations={setProfileConversations}
                />
              </Box>
              <Box sx={styleRightSide}>
                {profileConversations?.length ?
                  <ChatRightSide
                    variant={variantRightSide}
                    openChat={setMobileChat}
                    conversations={conversation}
                  />
                  :
                  <Box sx={attention}>
                    <Typography>No conversations yet</Typography>
                    <img src={NoConversations} alt="" />
                  </Box>
                }
              </Box>
            </Box>
          </Box>
        </Content>
      </TempWrapperNew>
    </Box>
  )
}

const contentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.down('sm')]:{
    gap: 0,
  }
})

const fullChatPage = (theme: Theme) => ({
  layoutWrapper: {
    [theme.breakpoints.down('sm')]: {
      '& .temp-Wrapper': {
        minHeight: 'unset',
        height: '100vh'
      }
    }
  },
  wrapper: {
    backgroundColor: theme.palette.background.default,
    // paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
      paddingY: 'unset'
    }
  },
  chatWrapper: {
    backgroundColor: '#fff',
    borderRadius: 0,
    maxWidth: '100%',
    width: '100%',
    marginX: 'auto',
    height: '725px',
    // overflowY: 'hidden',
    // overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '530px'
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: 'calc(100vh - 73px)',
      overflowX: 'hidden'
    }
  },
  chatWrapperBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: '-webkit-box',
      overflowX: 'hidden'
    }
  },
  styleMainLeftSideMobile: {
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(-100%)',
      transition: '0.6s',
      display: '-webkit-box'
    }
  },
  styleMainLeftSideMobileClose: {
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(0%)',
      transition: '0.6s',
      // overflowX: 'hidden',
      display: '-webkit-box'
    }
  },
  styleMainLeftSide: {
    borderRight: '3px',
    borderColor: '#F2F5F8',
    borderStyle: 'solid',
    paddingTop: '24px',
    borderTop: 'unset',
    borderBottom: 'unset',
    flex: '0 0 306px',
    [theme.breakpoints.down('md')]: {
      paddingY: 1
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      width: '100%',
      boxSizing: 'border-box',
      border: 'none',
      padding: 3
    }
  },
  styleRightSide: {
    padding: '29px 0 0 0',
    flex: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      paddingY: 1
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      width: '100%',
      padding: 'unset',
      height: '100%'
    }
  },
  variantRightSide: ChatVariant.CHAT_MAIN
})

const widgetChat = (theme: Theme) => ({
  layoutWrapper: {
    '& .main-header': {
      display: 'none'
    },
    '& .temp-Wrapper': {
      minHeight: 'unset'
    }
  },
  chatWrapper: {
    zIndex: '99!important',
    position: 'fixed',
    bottom: 0,
    width: 500,
    right: '100px',
    borderRadius: '10px 10px 0px 0px',
    [theme.breakpoints.down('md')]: {
      width: 420
    },
    [theme.breakpoints.down('sm')]: {
      top: 0,
      borderRadius: 'unset',
      backgroundColor: 'rgba(20, 29, 36, 0.8)',
      right: 0,
      left: 0,
      width: '100%'
    }
  },
  styleRightSide: {
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    height: 518,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px 10px 0px 0px',
    [theme.breakpoints.down('md')]: {
      height: 468
    },
    [theme.breakpoints.down('sm')]: {
      height: 518,
      position: 'absolute',
      width: '100%',
      left: 0,
      bottom: 0
    }
  },
  chatWrapperBox: {
    display: 'block'
  },
  styleMainLeftSide: {
    display: 'none'
  },
  variantRightSide: ChatVariant.CHAT_WIDGET,
  leftSide: 0,
  rightSide: 12,
  paddingY: 0
})

const attention = (theme: Theme) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& p': {
    color: 'rgba(20, 29, 36, 0.4)',
    fontSize: '22px',
    marginBottom: '27px'
  }
})

const titleWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems:'center',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
})