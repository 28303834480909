import { Box, Card, Divider, Theme, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { TypeOfComponent } from '../../pages/employee-profile-edit'
import { ApiGraphQLTypes, ApiInputType, ApiSelector } from 'recruticka-frontend-libs'
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../../hooks/useCurrencyFormatter'

const currencySelector = ApiSelector('Currency')({
  id: true,
  name: true,
  symbol: true,
})

const salaryHistoryRecordSelector = ApiSelector('SalaryHistoryRecord')({
  id: true,
  from: true,
  to: true,
  year: true,
})

export const salaryHistorySelector = ApiSelector('SalaryHistory')({
  id: true,
  schedule: true,
  currency: currencySelector,
  history: salaryHistoryRecordSelector
})

type CurrencyFields = ApiInputType<ApiGraphQLTypes['Currency'], typeof currencySelector>
type SalaryHistoryRecordFields = ApiInputType<ApiGraphQLTypes['SalaryHistoryRecord'], typeof salaryHistoryRecordSelector>
type SalaryHistoryFields = ApiInputType<ApiGraphQLTypes['SalaryHistory'], typeof salaryHistorySelector>

interface Props {
  salaryHistory?: SalaryHistoryFields
  employeeName?: string
  onChange?: () => void
  type?: TypeOfComponent
}

export const EmployeeEditBillings = ({ salaryHistory, employeeName, onChange, type }: Props) => {
  const { t } = useTranslation()

  return (
    <Card sx={wrapper}>
      <Box sx={headStyle}>
        <Typography variant={'h6'}>
          {employeeName && `${employeeName}’s `}
          Billings
        </Typography>
        {type === TypeOfComponent.EDIT && (
          <Box onClick={onChange}>
            <EditIcon sx={iconStyle} />
          </Box>
        )}
      </Box>
      {!!salaryHistory?.id && !!salaryHistory.history?.length && (
        <>
          <Typography variant={'body1'} color={(theme) => theme.palette.text.secondary}>
            {t(`enum.SalaryPeriod.${salaryHistory?.schedule}`)}
          </Typography>
          {salaryHistory.history?.map((record) => (
            <EmployeeEditBillingsHistoryItem key={record.id} currency={salaryHistory.currency} {...record} />
          ))}
          <Divider />
        </>
      )}
    </Card>
  )
}

interface EmployeeEditBillingsHistoryItemProps extends SalaryHistoryRecordFields {
  currency: CurrencyFields
}

const EmployeeEditBillingsHistoryItem = ({ from, to, currency, year }: EmployeeEditBillingsHistoryItemProps) => {
  const fromN = useCurrencyFormatter((from as number) || 0, currency.id)

  return (
    <Typography sx={dateStyle}>
      <span>{year}</span> - {fromN}
    </Typography>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  height: 'auto',
})

const headStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: '10px',
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

const dateStyle = (theme: Theme) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  display: 'flex',
  '& span': {
    width: '40px',
    marginRight: '5px',
    color: 'rgba(20, 29, 36, 0.4)',
    [theme.breakpoints.down('md')]: {
      width: '36px'
    }
  }
})
