import { useEffect, useState } from 'react'
import { Card, Theme, Box, Grid, CircularProgress, useMediaQuery } from '@mui/material'
import { SignStepper } from '../components/not-cms/sign-stepper'
import { Main, Content } from '../components/common-files/common-styles'
import { Final } from '../components/not-cms/steps/final'
import { KindOfPeopleInformation } from '../components/not-cms/employer-steps/kind-of-people-information'
import { HiringInLocations } from '../components/not-cms/employer-steps/hiring-in-locations'
import { WorkFromHere } from '../components/not-cms/employer-steps/work-from-home'
// import { AmountOfPeople } from '../components/not-cms/employer-steps/amount-people'
// import { BudgetInformation } from '../components/not-cms/employer-steps/budget-information'
// import { StructureIncentives } from '../components/not-cms/employer-steps/structure-incentives'
// import { ConsiderOther } from '../components/not-cms/employer-steps/consider-other'
// import { InterviewStages } from '../components/not-cms/employer-steps/interview-stages'
// import { CultureOfBusiness } from '../components/not-cms/employer-steps/culture-of-business'
import { ProfileStrength } from '../components/not-cms/profile-strength'
import TempWithoutSidebar from '../components/tempWithoutSidebar'
import { ReadAcceptStep } from '../components/not-cms/employer-steps/read-accept-step'
import { useQueryClient } from 'react-query'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { useAuthQueryRequest } from '../services/api'
import { CompanyUpdateFields, recruitmentFocusSelector, useUpdateCompany } from '../hooks/useCompany'
import { useSetupSteps } from '../hooks/useSetupSteps'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState, ProfileStateFields } from '../state/atoms/profileState'
import { useAcceptTermsProfile, useCompleteProfile } from '../hooks/useProfile'
import { assetSelector } from '../components/Uploader/Uploader'
import { citySelector } from '../components/form-fields/CityAutocomplete'
import theme from '../styles/theme'
// import { RecruitmentFocus } from '../components/not-cms/employer-steps/recruitment-focus'
// import { sectorSelector } from '../components/form-fields/RecruitmentFocusAutocomplete'
// import { generatePath, useNavigate } from 'react-router-dom'

enum Steps {
  KindOfPeopleInformation = 'KindOfPeopleInformation',
  // RecruitmentFocus = 'RecruitmentFocus',
  // AmountOfPeople = 'AmountOfPeople',
  HiringInLocations = 'HiringInLocations',
  // BudgetInformation = 'BudgetInformation',
  // StructureIncentives = 'StructureIncentives',
  WorkFromHere = 'WorkFromHere',
  // ConsiderOther = 'ConsiderOther',
  // InterviewStages = 'InterviewStages',
  // CultureOfBusiness = 'CultureOfBusiness',
  ReadAcceptStep = 'ReadAcceptStep'
}

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

const companySelector = ApiSelector('Company')({
  id: true,
  jobTypes: {
    id: true,
    name: true,
  },
  lookingForPeople: true,
  hiringInCities: citySelector,
  commissionAndIncentivesFiles: assetSelector,
  basicSalary: salarySelector,
  recruitmentsFocus: recruitmentFocusSelector,
  commissionAndIncentives: true,
  workFromHome: true,
  workFromHomeDays: true,
  considerPeopleFromOtherSector: true,
  inverviewStages: true,
  cultureOfBusiness: true,
  cultureOfBusinessFiles: assetSelector,
})

type CompanyFields = ApiInputType<ApiGraphQLTypes['Company'], typeof companySelector>
type SalaryFields = ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>

type StepChecker = {
  [key in keyof typeof Steps]: (company: CompanyFields & ProfileStateFields) => boolean
}

const stepChecker: Partial<StepChecker> = {
  [Steps.KindOfPeopleInformation]: (company) => Boolean(company?.jobTypes?.length),
  // [Steps.AmountOfPeople]: (company) => Boolean(company?.lookingForPeople),
  // [Steps.RecruitmentFocus]: (company) => Boolean(company?.recruitmentsFocus?.length),
  [Steps.HiringInLocations]: (company) => Boolean(company?.hiringInCities?.length),
  // [Steps.BudgetInformation]: (company) => Boolean(company?.basicSalary),
  // [Steps.StructureIncentives]: (company) => Boolean(company?.commissionAndIncentives),
  [Steps.WorkFromHere]: (company) => typeof company?.workFromHome === 'boolean',
  // [Steps.ConsiderOther]: (company) => Boolean(company?.considerPeopleFromOtherSector),
  // [Steps.InterviewStages]: (company) => Boolean(company?.inverviewStages?.length),
  // [Steps.CultureOfBusiness]: (company) => Boolean(company?.cultureOfBusiness),
  [Steps.ReadAcceptStep]: (profile) => profile?.termsAndConditionsAccepted
}

const SignUpEmployer = () => {

  const [currentStep, setCurrentStep] = useState(0)
  const profile = useRecoilValue(profileState)
  const company = useRecoilValue(mainCompanyState)
  const profileStatus = profile?.status
  const completeProfileMutation = useCompleteProfile()
  const acceptTermsProfileMutation = useAcceptTermsProfile()
  const updateCompanyMudation = useUpdateCompany()
  const queryClient = useQueryClient()
  const matchMd = useMediaQuery(theme.breakpoints.down('md'))


  const saveCompany = async (values: Omit<CompanyUpdateFields, 'id'>, toNextStep = true): Promise<void> => {
    const data = await updateCompanyMudation.mutateAsync({
      values: {
        ...values,
        id: company.id as string,
      },
    })

    if (data) {
      queryClient.setQueryData('company', {
        getCompany: {
          ...data,
        },
      })
    }

    if (toNextStep) {
      goToNextStep()
    }
  }

  const { data: companyData, isLoading: isCompanyDataLoading } = useAuthQueryRequest(
    'company',
    {
      getCompany: [{ where: { id: company.id as string } }, companySelector],
    },
    {
      enabled: company.id !== undefined,
    },
  )

  const { activeStep, goToNextStep, goToPrevStep, stepsKeys, stepsValidated } = useSetupSteps({
    steps: Steps,
    stepChecker: stepChecker,
    stepCheckerData:
      companyData?.getCompany && profile
        ? {
          ...companyData.getCompany,
          ...profile,
        }
        : undefined,
  })


  useEffect(() => {
    setCurrentStep(Math.max(0, stepsKeys.indexOf(activeStep)))
  }, [activeStep, stepsKeys])
  
  const updateBasicSalaryInCompany = (basicSalary: SalaryFields) => {
    queryClient.setQueryData('company', {
      getCompany: {
        ...companyData?.getCompany,
        basicSalary,
      },
    })

    goToNextStep()
  }

  const acceptTermsAndMarkProfileAsCompleted = async (termsAndConditionsAccepted: boolean) => {
    if (termsAndConditionsAccepted && profile) {
      if (!profile?.termsAndConditionsAccepted) {
        await acceptTermsProfileMutation.mutateAsync(profile.id)
      }

      await completeProfileMutation.mutateAsync(profile.id)
    }

    goToNextStep()
  }

  if (isCompanyDataLoading || !companyData?.getCompany.id || stepsValidated === undefined) {
    return <CircularProgress />
  }

  return (
    <TempWithoutSidebar>
      <Main sx={wrapper}>
        <Content>
          <Grid container spacing={1} justifyContent="center">
            <Grid item md={10} xs={12} >
              
              <ProfileStrength value={company.strength ? company.strength : 0} showBar={profileStatus === ApiTypes.ProfileStatus.COMPLETED} isClient={true} />

              {profileStatus !== ApiTypes.ProfileStatus.COMPLETED && (
                  <SignStepper  currentStep={currentStep} activeStep={activeStep} steps={stepsKeys} />
              )}

              <Card sx={contentWrapper}>
                <Box sx={stepContentWrapper}>
                  {profileStatus === ApiTypes.ProfileStatus.COMPLETED && <Final isEmployer={true} />}
                  {profileStatus !== ApiTypes.ProfileStatus.COMPLETED && (
                    <>
                      {activeStep === Steps.KindOfPeopleInformation && (
                        <KindOfPeopleInformation
                          isSaving={updateCompanyMudation.isLoading}
                          jobTypes={companyData.getCompany.jobTypes}
                          recruitmentsFocus={companyData.getCompany.recruitmentsFocus}
                          lookingForPeople={companyData.getCompany.lookingForPeople}
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          onSave={async (jobTypes, recruitmentsFocus, lookingForPeople) => {
                            await saveCompany({ jobTypes, recruitmentsFocus, lookingForPeople,  })
                          }}
                        />
                      )}
                      {/* {activeStep === Steps.RecruitmentFocus &&
                        <RecruitmentFocus
                          isSaving={updateCompanyMudation.isLoading}
                          recruitmentsFocus={companyData.getCompany.recruitmentsFocus}
                          onSave={async (recruitmentsFocus) => {
                            await saveCompany({ recruitmentsFocus })
                          }}
                        />
                      }
                      {activeStep === Steps.AmountOfPeople && (
                        <AmountOfPeople
                          isSaving={updateCompanyMudation.isLoading}
                          lookingForPeople={companyData.getCompany.lookingForPeople}
                          onSave={async (lookingForPeople) => {
                            await saveCompany({ lookingForPeople })
                          }}
                        />
                      )} */}

                      {activeStep === Steps.HiringInLocations && (
                        <HiringInLocations
                          isSaving={updateCompanyMudation.isLoading}
                          hiringInCities={companyData.getCompany.hiringInCities}
                          companyId={companyData.getCompany.id}
                          {...companyData.getCompany.basicSalary}
                          commissionAndIncentives={companyData.getCompany.commissionAndIncentives}
                          commissionAndIncentivesFiles={companyData.getCompany.commissionAndIncentivesFiles}
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          onSave={async (hiringInCities, commissionAndIncentives, commissionAndIncentivesFiles) => {
                            await saveCompany({ hiringInCities,commissionAndIncentives, commissionAndIncentivesFiles })
                          }}
                        />
                      )}

                      {/* {activeStep === Steps.BudgetInformation && (
                        <BudgetInformation
                          companyId={companyData.getCompany.id}
                          isSaving={updateCompanyMudation.isLoading}
                          {...companyData.getCompany.basicSalary}
                          onSave={updateBasicSalaryInCompany}
                        />
                      )} 

                      {activeStep === Steps.StructureIncentives && (
                        <StructureIncentives
                          isSaving={updateCompanyMudation.isLoading}
                          commissionAndIncentives={companyData.getCompany.commissionAndIncentives}
                          commissionAndIncentivesFiles={companyData.getCompany.commissionAndIncentivesFiles}
                          onSave={async (commissionAndIncentives, commissionAndIncentivesFiles) => {
                            await saveCompany({ commissionAndIncentives, commissionAndIncentivesFiles })
                          }}
                        />
                      )}
                    */}
                      {activeStep === Steps.WorkFromHere && (
                        <WorkFromHere
                          isSaving={updateCompanyMudation.isLoading}
                          workFromHome={companyData.getCompany.workFromHome}
                          workFromHomeDays={companyData.getCompany.workFromHomeDays}
                          considerPeopleFromOtherSector={companyData.getCompany.considerPeopleFromOtherSector}
                          inverviewStages={companyData.getCompany.inverviewStages}
                          cultureOfBusiness={companyData.getCompany.cultureOfBusiness}
                          cultureOfBusinessFiles={companyData.getCompany.cultureOfBusinessFiles}
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          onSave={async ({ workFromHome, workFromHomeDays, considerPeopleFromOtherSector, inverviewStages, cultureOfBusiness, cultureOfBusinessFiles }) => {
                            await saveCompany({workFromHome, workFromHomeDays, considerPeopleFromOtherSector, inverviewStages, cultureOfBusiness, cultureOfBusinessFiles })
                          }}
                        />
                      )}

                      {/* {activeStep === Steps.ConsiderOther && (
                        <ConsiderOther
                          isSaving={updateCompanyMudation.isLoading}
                          considerPeopleFromOtherSector={companyData.getCompany.considerPeopleFromOtherSector}
                          onSave={async (considerPeopleFromOtherSector) => {
                            await saveCompany({ considerPeopleFromOtherSector })
                          }}
                        />
                      )}

                      {activeStep === Steps.InterviewStages && (
                        <InterviewStages
                          isSaving={updateCompanyMudation.isLoading}
                          inverviewStages={companyData.getCompany.inverviewStages}
                          onSave={async (inverviewStages) => {
                            await saveCompany({ inverviewStages })
                          }}
                        />
                      )} 

                      {activeStep === Steps.CultureOfBusiness && (
                        <CultureOfBusiness
                          isSaving={updateCompanyMudation.isLoading}
                          cultureOfBusiness={companyData.getCompany.cultureOfBusiness}
                          cultureOfBusinessFiles={companyData.getCompany.cultureOfBusinessFiles}
                          onSave={async (cultureOfBusiness, cultureOfBusinessFiles) => {
                            await saveCompany({ cultureOfBusiness, cultureOfBusinessFiles })
                          }}
                        />
                      )}
                      */}
                      {activeStep === Steps.ReadAcceptStep && (
                        <ReadAcceptStep
                          currentStep={currentStep}
                          backStep={goToPrevStep}
                          linkForTerms={'https://recruitica.io/terms-of-business-clients'}
                          isSaving={updateCompanyMudation.isLoading || completeProfileMutation.isLoading}
                          termsAndConditionsAccepted={profile?.termsAndConditionsAccepted}
                          onSave={async (termsAndConditionsAccepted) => {
                            await acceptTermsAndMarkProfileAsCompleted(termsAndConditionsAccepted)
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Main>
    </TempWithoutSidebar>
  )
}

const wrapper = (theme: Theme) => ({
  paddingTop: '0',
  paddingBottom: '128px',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('xs')]:{
    '&': {
      '& .MuiBox-root': {
        '& .MuiBox-root.MuiGrid-item': {
          width: '100%',
        }
      },
    },
  }
})

const contentWrapper = (theme: Theme) => ({
  width: '100%',
  height: 'auto',
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(1),
  marginBottom: '20px',
  py: '0',
  [theme.breakpoints.down('md')]:{
    py: '0',
  },
  [theme.breakpoints.down('sm')]:{
    py: '0',
  },
  [theme.breakpoints.down('xs')]:{
    py: '0',
  }
})


const stepContentWrapper = (theme: Theme) => ({
  display: 'flex',
  width: '100%',
  alignSelf: 'center',
  // alignItems: 'center',
  minHeight: '600px',
  '&': {
    position: 'relative',
    paddingBottom: '140px',

  },
  '& > *': {
    width: '100%',
    minHeight: '281px',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  '& .stepFieldWrapper' : {
    padding: '64px 0%',
    maxWidth: '800px',
    margin: '0 auto',
    width: '100%',
  },
  '& .stepFooter' : {
    borderTop: '1px solid #cfcfcf',
    padding: '24px 10px',
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 0,
    bottom: 0,
    
    // '& button' : {
    //   width: '210px'
    // }
  },
  [theme.breakpoints.down('md')]:{
    'form': {
      // '& .stepFieldWrapper:first-of-type' : {
      //   paddingTop: theme.spacing(2),
      // }  
    },
    '& .stepFieldWrapper' : {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    '& .stepFooter' : {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      px: theme.spacing(0),
    }
  },
  [theme.breakpoints.down('sm')]:{
    '&': {
      paddingBottom: '110px',
    },
    '& > *': {
      minHeight: '190px',
    },
    '& .stepFooter' : {
      '& .MuiButton-containedPrimary': {
        paddingLeft: '14px !important',
        paddingRight: '14px !important',
      }
    }

  }
})

export default SignUpEmployer
