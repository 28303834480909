import { Content } from '../components/common-files/common-styles'
import { 
  Box, 
  CircularProgress, 
  Grid, 
  Theme, 
  Typography, 
  useMediaQuery,
} from '@mui/material'
import { ProfileStrength } from '../components/not-cms/profile-strength'
import { useQueryRequest, useAuthQueryRequest } from '../services/api'
import { employeeSelector as employeeSelectorBase } from '../hooks/useEmployee'
import { useRecoilValue } from 'recoil'
import { profileState } from '../state/atoms/profileState'
import { assetSelector } from '../components/Uploader/Uploader'
import theme from '../styles/theme'
import TempWrapperNew from '../components/tempWrapperNew'
import { CandidateJobCard } from '../components/CandidateJobCard'
import CandidateProfileCard from '../components/CandidateProfileCard'
import CandidateJobSearchFilters from '../components/CandidateJobSearchFilters'
import { salarySelector, jobLocationSelector } from './add-job-page'
import { useNavigate, useSearchParams, generatePath, Link as RouterLink } from 'react-router-dom'
import { useList } from '../hooks/useList'
import { useDebounce } from 'use-debounce'
import { useQueryClient } from 'react-query'
import { textBlockSelector } from '../hooks/useTextBlock'
import { ApiTypes, ApiGraphQLTypes, ApiSelector, ApiInputType } from 'recruticka-frontend-libs'


export const jobSelector = ApiSelector('Job')({
  id: true,
  title: true,
  slug: true,
  status: true,
  publishedAt: true,
  coverImage: assetSelector,
  company: {
    name: true,
    logo: assetSelector
  },
  shortDescription: true,
  sectors: {
    name: true,
  },
  salary: {
    from: true,
    to: true,
    currency: {
      id: true
    }
  },
  salaryType: true,
  location: {
    type: true,
    wfhDays: true,
    offices: {
      city: {
        name: true
      }
    }
  },
  _count: {
    application: true
  }
})

export type JobFields = ApiInputType<ApiGraphQLTypes['Job'], typeof jobSelector>


export enum TypeOfComponent {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export const employeeSelector = ApiSelector('Employee')({
  ...employeeSelectorBase,

  cv: assetSelector,
  publishedAt: true,
  coverImage: assetSelector,
  avatar: assetSelector,
  salaryHistory: {
    id: true,
    schedule: true,
    currency: {
      id: true,
      name: true,
      symbol: true,
    },
    history: {
      id: true,
      from: true,
      to: true,
      year: true,
    }
  },
  profile: {
    displayName: true,
    email: true,
    linkedin: true,
    hideFromEveryone: true,
    hiddenFromProfile: {
      id: true,
      companies: {
        id: true,
        profileId: true,
        name: true,
        status: true,
        logo: {
          url: true,
        },
        isMain: true,
        sisterCompanies: {
          profileId: true,
          name: true,
          isMain: true,
          status: true,
        }
      }
    },
    visibleForProfile: {
      id: true,
      companies: {
        id: true,
        profileId: true,
        name: true,
        status: true,
        logo: {
          url: true,
        },
        isMain: true,
        sisterCompanies: {
          profileId: true,
          name: true,
          isMain: true,
          status: true,
        }
      },
    },
  },
})

interface IAlert {
  isOpen: boolean
  id?: string
}

const initialAlert: IAlert = {
  isOpen: false,
  id: undefined
}


const EmployeeProfileEdit = () => {
  
  const profile = useRecoilValue(profileState)
  const mdWidth = useMediaQuery(theme.breakpoints.down('md'))
  const queryClient = useQueryClient()
  /* Jobs */
  const [searchParams, setSearchParams] = useSearchParams()
  const status: ApiTypes.JobStatus = (searchParams.get('status') as ApiTypes.JobStatus) || ApiTypes.JobStatus.ACTIVE
  const { page, perPage, orderBy, updatePage, updatePerPage } = useList()
  const setStatus = (status: ApiTypes.JobStatus) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      status
    })
  }

  const {
    data: employeeData,
    isLoading: isEmployeeDataLoading,
    isRefetching: isEmployeeDataRefetching
  } = useAuthQueryRequest(
    'employee',
    {
      getEmployee: [{ id: profile?.employee?.id as string }, employeeSelector]
    },
    {
      enabled: profile?.employee?.id !== undefined
    }
  )

  const jobsParams = { status, rowsPerPage: perPage, page, orderBy }
  const { 
    data: jobData, 
    isLoading: jobDataLoading,
    isRefetching: jobDataRefetching
  } = useAuthQueryRequest(['job', { status, rowsPerPage: perPage, page, orderBy }], {
    getJobs: [
        { 
          take: perPage,
          skip: page * perPage,
          orderBy 
        }, 
        jobSelector
      ]
    }
  )

  if (isEmployeeDataLoading || isEmployeeDataRefetching || !employeeData?.getEmployee) {
    return <CircularProgress />
  }

  return (
    <TempWrapperNew>
      <Content sx={contentWrapper}> 
        <Box sx={titleWrapper}>
          <Typography variant={'h4'}>{'Dashboard'}</Typography> 
        </Box>
        <Box sx={profileStrengthBox}>
           <ProfileStrength value={profile?.employee?.strength ? profile?.employee?.strength : 0} showBar={true} isClient={false} /> 
        </Box>
      
        {/* <CandidateJobSearchFilters status={status} setStatus={setStatus} /> */}
      {/* { JSON.stringify(jobData?.getJobs) } */}
        <Grid container spacing={4} sx={gridContainer}>
          <Grid item xs={mdWidth ? 12 : 8} sx={gridStyles}>
            <Grid container spacing={2}>
              {(jobData?.getJobs as JobFields[] || []).map((job, index) => {
                  return (<CandidateJobCard key={job.id} job={job}/>)
              })}
            </Grid>
          </Grid>

          <Grid item xs={mdWidth ? 12 : 4} sx={gridStyles}>
            <CandidateProfileCard profile={profile} getEmployee={employeeData?.getEmployee} />
          </Grid>
        </Grid>
      </Content>
    </TempWrapperNew>
  )
}

const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  zIndex: '2',
  marginBottom: '180px',
  boxSizing: 'border-box', [theme.breakpoints.down('md')]: {
    marginBottom: '70px',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '40px',
  }
})

const gridContainer = (theme: Theme) => ({
  mt: theme.spacing(0),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  }
})

const gridStyles = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('md')]: {
    flexBasis: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    '& h2': {
      fontSize: '18px'
    }
  }
})

const titleWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems:'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'start',
    gap: theme.spacing(2),
  }
})

const profileStrengthBox = (theme: Theme) => ({
  mt: theme.spacing(1),
  width: '100%',
  '& .infoStyle': {
    textAlign: 'right',
  },
  [theme.breakpoints.down('md')]: {
    mt: theme.spacing(2),
    '& .infoStyle': {
      textAlign: 'left',
    },
    '& .progressWrapper': {
      '& .MuiTypography-h2': {
        fontSize: '18px',
        lineHeight: '20px',
      }
    },
  },
})


export default EmployeeProfileEdit
