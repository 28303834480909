import { ApiTypes } from 'recruticka-frontend-libs'

const translation = {
  startTyping: 'Start typing...',
  back: 'Back',
  save: 'Save',
  saveNext: 'Save & Next',
  next: 'Next',
  iAgree: 'I Agree', 
  otherPleaseSpecify: 'Other, please specify',
  yes: 'Yes',
  no: 'No',
  startDate: 'Start Date',
  endDate: 'End Date',
  description: 'Description',
  founded: 'Founded',
  website: 'Website',
  shortDescription: 'Short description',
  aboutUs: 'About us',
  address: 'Address',
  add: 'Add',
  all: 'All',

  applicationStatusActions: {
    [ApiTypes.ApplicationStatus.ACTIVE]: 'Active',
    [ApiTypes.ApplicationStatus.FOUND_ANOTHER_JOB]: 'Found another job',
    [ApiTypes.ApplicationStatus.HIRED]: 'Hire',
    [ApiTypes.ApplicationStatus.INVITED]: 'Invite',
    [ApiTypes.ApplicationStatus.REJECTED]: 'Reject',
  },

  enum: {
    Experience: {
      [ApiTypes.Experience.ENTRY]: 'Entry',
      [ApiTypes.Experience.JUNIOR]: 'Junior',
      [ApiTypes.Experience.LEADERSHIP]: 'Leadership',
      [ApiTypes.Experience.MIDDLE]: 'Middle',
      [ApiTypes.Experience.SENIOR]: 'Senior',
    },
    JobLocationType: {
      [ApiTypes.JobLocationType.OFFICE]: 'Office',
      [ApiTypes.JobLocationType.REMOTE]: 'Remote',
      [ApiTypes.JobLocationType.WFH]: 'Hybrid',
    },
    SalaryPeriod: {
      [ApiTypes.SalaryPeriod.MONTH]: 'Per month',
      [ApiTypes.SalaryPeriod.ANNUAL]: 'Per year',
    },
    EducationLevel: {
      [ApiTypes.EducationLevel.DEGREE]: 'Degree',
      [ApiTypes.EducationLevel.HIGH_SCHOOL]: 'High School',
      [ApiTypes.EducationLevel.MASTERS]: 'Masters',
    },
    DeskType: {
      [ApiTypes.DeskType.CONTRACT]: 'Contract',
      [ApiTypes.DeskType.DUAL_DESK]: 'Dual Desk',
      [ApiTypes.DeskType.PERMANENT]: 'Permanent',
    },
    ConsiderPeopleFromOtherSector: {
      [ApiTypes.ConsiderPeopleFromOtherSector.YES]: 'Yes',
      [ApiTypes.ConsiderPeopleFromOtherSector.NO]: 'No',
      [ApiTypes.ConsiderPeopleFromOtherSector.MAYBE]: 'Maybe',
    },
    InterviewStage: {
      [ApiTypes.InterviewStage.BUSINESS_PLAN_PRESENTATION]: 'Business Plan/Presentation',
      [ApiTypes.InterviewStage.FACE_TO_FACE_INTERVIEW]: 'Face to Face Interview',
      [ApiTypes.InterviewStage.ONLINE_ASSESSMENT]: 'Online Assessment',
      [ApiTypes.InterviewStage.ROLE_PLAYS]: 'Role Plays',
      [ApiTypes.InterviewStage.CULTURAL_FIT_INTERVIEWERVIEW]: 'Cultural Fit Interview',
      [ApiTypes.InterviewStage.TELEPHONE_INTERVIEW]: 'Telephone Interview',
      [ApiTypes.InterviewStage.TRIAL_DAY]: 'Trial Day',
      [ApiTypes.InterviewStage.VIDEO_INTERVIEW]: 'Video Interview',
    },
    CompanyPerkType: {
      [ApiTypes.CompanyPerkType.LEARNING_DEVELOPMENT]: 'Learning & Development',
      [ApiTypes.CompanyPerkType.TIME_OFF]: 'Time Off',
      [ApiTypes.CompanyPerkType.FLEXIBLE_WORKING]: 'Flexible Working',
      [ApiTypes.CompanyPerkType.FINANCIAL_BENEFITS]: 'Financial Benefits',
      [ApiTypes.CompanyPerkType.INCLUSION_DIVERSITY]: 'Inclusion & Diversity',
      [ApiTypes.CompanyPerkType.HEALTS_WELLNESS]: 'Health & Wellness',
      [ApiTypes.CompanyPerkType.OFFICE_ENVIRONMENT]: 'Office Environment',
      [ApiTypes.CompanyPerkType.MENTAL_HEALTS_SUPPORT]: 'Mental Health Support',
    },
    JobSalaryType: {
      [ApiTypes.JobSalaryType.CONTRACT]: 'Contract',
      [ApiTypes.JobSalaryType.GET_IN_TOUCH]: 'Get in touch',
    },
    ApplicationStatus: {
      [ApiTypes.ApplicationStatus.ACTIVE]: 'Active',
      [ApiTypes.ApplicationStatus.FOUND_ANOTHER_JOB]: 'Found another job',
      [ApiTypes.ApplicationStatus.HIRED]: 'Hired',
      [ApiTypes.ApplicationStatus.INVITED]: 'Invited',
      [ApiTypes.ApplicationStatus.REJECTED]: 'Rejected',
    },
    CompanySize: {
      [ApiTypes.CompanySize.LESS_10]: '<10',
      [ApiTypes.CompanySize.BETWEEN_11_20]: '11-20',
      [ApiTypes.CompanySize.BETWEEN_21_50]: '21-50',
      [ApiTypes.CompanySize.BETWEEN_51_100]: '51-100',
      [ApiTypes.CompanySize.BETWEEN_101_1000]: '101-1000',
      [ApiTypes.CompanySize.MORE_1000]: '1000+',
    },
  },
  formFields: {
    experienceLabel: 'Experience',
    glassdoor: 'Glassdoor public profile url',
    trustpilot: 'Trustpilot public profile url',
    reasonForLeavingRecentEmployerTitle:
      'Why are you looking to leave your current company/Why did you leave your last employer?',
    reasonForLeavingRecentEmployerTitleShort: 'Reason for leaving your most recent employer?',
    recruitRolesTitle: 'Which roles do you recruit?',
    canBringExistingClientsTitle: 'Can you bring your existing clients to your next role?',

    jobCriteriasTitle: 'What’s most important to you in your next role?',
    jobCriteriasHelpText: 'Add a maximum of 5',

    jobTypesTitle: 'What position are you looking for?',
    companyJobTypesTitle: 'What kind of people are you looking for? ',

    sectorsTitle: 'Recuitment Sector',
    specializationsTitle: 'Specialization',

    recruitmentFocusTitle: 'Recruitment Focus',

    languagePlaceholder: 'Languages',
    currencyPlaceholder: 'Currency',
    jobLocationPlaceholder: 'Start typing city name',
    locationPlaceholder: 'Location',
    locationTypePlaceholder: 'Type of Work',
    experiencePlaceholder: 'What your experience level?',
    firstnamePlaceholder: 'First Name',
    lastnamePlaceholder: 'Last Name',
    readinessForWorkPlaceholder: 'Type of Work',

    workExperienceTItle: 'Job Title',
    workExperienceCompanyName: 'Company Name',
    workExperienceIsWorkingHere: 'I am Currently Working Here',

    educationTItle: 'Field of study',
    educationSchool: 'School Name',
    educationGrade: 'Grade',
    educationIsStudyHere: 'I am Currently Study Here',

    companyName: 'Company name',

    officeIsMain: 'Make it the main',

    jobTitle: 'Job Title',
    jobShortDescriptionPlaceholder: 'Add your short description here...',
    jobDescriptionTitle: 'Job Description',
    jobDescriptionPlaceholder: 'Add your job description here...',

    salaryPeriodPlaceholder: 'Period',
    countriesPlaceholder: 'In which countries do you recruit?',

    clientIndustriesTitle: 'In which industries are your clients?',

    companyLookingForPeopleTitle: 'How many people are you looking for?',
    companyBasicSalaryTitle: 'What’s your budget for basic salaries?',
    companyCommissionAndIncentives: 'What’s your commission structure and incentives?',
    companyConsiderPeopleFromOtherSector: 'Would you consider people from other sectors?',
    companyCultureOfBusinessTitleTitle: 'What’s the culture of your business?',
  },
  companyInformations: {
    lookingFor: 'We’re Looking For',
    location: 'Location',
    basicSalary: 'Budget for Basic Salaries',
    comissionStructureIncentives: 'Commission Structure & Incentives',
    interviewSteps: 'Interview Process',
    cultureOfBusiness: 'Company Culture',
    reviews: 'Reviews'
  },
  companyForm: {
    logoId: 'Logo',
    name: 'Comapany name',
    foundedAt: 'Founded',
    size: 'Company size',
    shortDescription: 'Short description',
    description: 'About us',
    considerPeopleFromOtherSector: 'Candidates from different sectors',
    commissionAndIncentives: 'Commission Structure & Incentives',
    cultureOfBusiness: 'Company Culture',
    lookingForPeople: 'How many people we are looking for',
    workFromHome: 'Hybrid',
    workFromHomeDays: 'Working from home days is allowed',
    inverviewStages: 'Interview Process',
    offices: 'Office locations',
    jobTypes: 'What kind of people are you looking for',
    hiringInCities: 'Locations we’re hiring ',
    cover: 'Cover',
    basicSalary: 'Budget for basic Salaries',
  },
  salaryForm: {
    from: 'From',
    to: 'To',
    schedule: 'Period',
    currencyId: 'Currency',
    currency: 'Currency',
  },
}

export default translation
