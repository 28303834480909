import { Card, Typography, Grid, Box, Theme, MenuItem, Button, Link, Divider } from '@mui/material'
import { Field, useField, useFormikContext } from 'formik'
import { TextField, Select } from 'formik-mui';
import { DatePicker } from '../DatePicker'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import Uploader, { Asset } from '../Uploader/Uploader'
import { Avatar } from '../Avatar'
import { TextEditorField } from '../form-fields/TextEditorField'
import { ApiTypes } from 'recruticka-frontend-libs';
import { CompanySizeSelect } from '../form-fields/CompanySIzeSelect';
import { IMAGES } from '../../config/uploaderTypes'
import { ValidationError } from '../../pages/company-profile-setup'
import Logo from '../../images/mastercardLogo.png'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState } from '../../state/atoms/profileState'
import { generatePath, Link as RouterLink, useNavigate } from 'react-router-dom'
import { PAGES } from '../../router/AppRoutes'
import { buildAssetUrl } from '../../utilities/buildAssetUrl'
import { useQueryClient } from 'react-query'

interface Props {
  publishErrors?: ValidationError
  isMain: boolean
}

export const CompanyInformation = ({ publishErrors, isMain }: Props) => {
  const {errors} : any = useFormikContext()
  const profile = useRecoilValue(profileState)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const mainCompany = useRecoilValue(mainCompanyState)
  const navigate = useNavigate()
  const [logoField, , logoHelpers] = useField<Asset | null>('logo')
  const maxFoundedAt = useMemo(() => {
    return dayjs()
  }, [])

  const goToMainCompany = async () => {
    await queryClient.refetchQueries()
    navigate(
      generatePath(PAGES.COMPANIES, {
        profileId: profile?.id,
        companyId: mainCompany?.id
      }))
  }

  return (
    <Box sx={wrapper}>
      <Typography
        variant={'h2'}
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5),
          },
        })}
      >
        Image & Description
      </Typography>
      <Divider sx={(theme) => ({
          marginBottom: theme.spacing(4)
        })} />
      {!isMain &&
        <Box sx={mainCompanyWrapper}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {mainCompany?.logo?.url ?
              <img src={buildAssetUrl(mainCompany?.logo?.url)} alt="" />
              :
              <Avatar />
            }
            <Box>
              <Typography variant={'h5'} sx={{ mb: '6px' }}>Main company</Typography>
              <Typography variant={'body1'}>{mainCompany?.name}</Typography>
            </Box>
          </Box>
          <Button size='circleResponsiveMedium' variant={'outlined'} onClick={goToMainCompany}>Main company profile</Button>
        </Box>
      }
      <Grid container spacing={2} mb={4}>
        <Grid item sm={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={[{ display: 'flex', flexDirection: 'column',  gap: '24px' },
            publishErrors?.logoId ? {
            '& .uppy-Wrapper': {
              borderColor: '#D32F2F'
            }
          } : {}]}>
            <Avatar src={logoField.value?.url} sx={avatarWrapper} />
            <Uploader
              label={'Avatar'}
              assets={logoField.value}
              fullPreview
              allowedFileTypes={IMAGES}
              onFileUploaded={(file) => {
                logoHelpers.setValue(file)
              }}
              onFileDeleted={() => {
                logoHelpers.setValue(null)
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>

        <Grid item sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Field
              component={TextField}
              name='name'
              label={t('formFields.companyName')}
              fullWidth
              sx={(publishErrors?.name || errors.name) ? errorBorder : {}}
            />
          </Box>
        </Grid>
        <Grid item sm={6} xs={12} sx={{ }}>
          <Field component={TextField} name='website' label={t('website')} fullWidth />
        </Grid>
        <Grid item sm={6} xs={12} sx={{ }}>
            <Box sx={publishErrors?.foundedAt ? editorError : {}}>
              <Field
                component={DatePicker}
                label={'Founded'}
                name='foundedAt'
                mask='__.__.____'
                inputFormat='DD.MM.YYYY'
                textField={{ fullWidth: true }}
                maxDate={maxFoundedAt}
              />
            </Box>
        </Grid>
        <Grid item sm={6} xs={12} sx={selectField}>
          <CompanySizeSelect />
        </Grid>
        <Grid item sm={6} xs={12} sx={{ }}>
          <Field
              component={TextField}
              name='linkedin'
              label={'Linkedin'}
              placeholder={'https://linkprofile/linkedn'}
              fullWidth
            />
        </Grid>
        <Grid item sm={6} xs={12} sx={{ }}>
          <Field
            component={TextField}
            name='shortDescription'
            label={t('shortDescription')}
            fullWidth
            sx={publishErrors?.shortDescription ? errorBorder : {}}
          />
        </Grid>
        <Grid item xs={12} sx={{ }}>
          <Box sx={publishErrors?.description ? editorError : {}}>
            <TextEditorField
              name='description'
              placeholder='About Us'
              areaSx={{ height: 333 }}
            />
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}

const mainCompanyWrapper = (theme: Theme) => ({
  backgroundColor: '#F2F5F8',
  borderRadius: '6px',
  paddingX: '24px',
  paddingY: '16px',
  mb: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& img': {
    width: '72px',
    height: '72px',
    objectFit: 'cover',
    borderRadius: '50%',
  },
  '& .MuiAvatar-root': {
    width: '72px',
    height: '72px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      marginRight: 0,
      marginLeft: 'auto',
      mt: '12px',
      paddingX: '24px'
    }
  }
})

const editorError = (theme: Theme) => ({
  border: '1px solid rgb(211 47 47)',
  borderRadius: '6px',
})

const errorBorder = (theme: Theme) => ({
  '& fieldset': {
    borderColor: '#D32F2F'
  }
})

const wrapper = (theme: Theme) => ({
  '& p.Mui-error':{
    display: 'none'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
})

const avatarWrapper = (theme: Theme) => ({
  height: '122px',
  width: '122px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
})

const selectField = (theme: Theme) => ({
  '& .MuiFormControl-root': {
    width: '100%',
  }
})
