import { useField } from 'formik'
import { Button } from '../../ui-kits/button/button'
import { Box, Theme, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ApiTypes } from 'recruticka-frontend-libs'
import { $enum } from 'ts-enum-util'
import { useTranslation } from 'react-i18next'

export const InterviewProcessStages = () => {
  const { t } = useTranslation()
  const [inverviewStagesField, , inverviewStagesHelpers] = useField<ApiTypes.InterviewStage[]>('inverviewStages')

  return (
    <Box sx={wrapper}>
        <Box sx={stagesWrapper}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup sx={[checkBoxGroup]}>
              {$enum(ApiTypes.InterviewStage)
                .getKeys()
                .map((item) => {
                const isSelected = inverviewStagesField.value.includes(item as ApiTypes.InterviewStage)
                const text = t(`enum.InterviewStage.${item}`)
                return (
                  // <Button
                  //   title={text}
                  //   variant={isSelected ? 'contained' : 'outlined'}
                  //   size={'small'}
                  //   key={item}
                  //   sx={[
                  //     !isSelected
                  //       ? {
                  //         borderColor: 'black',
                  //         color: 'black',
                  //       }
                  //       : {},
                  //     {
                  //       px: 1
                  //     }
                  //   ]
                  //   }
                  //   onClick={() => {
                  //     if (isSelected) {
                  //       return inverviewStagesHelpers.setValue(inverviewStagesField.value.filter((elem) => elem !== item))
                  //     }

                  //     return inverviewStagesHelpers.setValue([
                  //       ...inverviewStagesField.value,
                  //       item as ApiTypes.InterviewStage,
                  //     ])
                  //   }}
                  // >
                  //   <Typography variant='inherit' noWrap>
                  //     {text}
                  //   </Typography>
                  // </Button>
                  
                  <Box sx={checkBox} key={item}>
                    <FormControlLabel
                      control={
                      <Checkbox checked={isSelected ? true : false}  name={text}  onChange={() => {
                        if (isSelected) {
                          return inverviewStagesHelpers.setValue(inverviewStagesField.value.filter((elem) => elem !== item))
                        }
                  
                        return inverviewStagesHelpers.setValue([
                          ...inverviewStagesField.value,
                          item as ApiTypes.InterviewStage,
                        ])
                      }} />
                    }
                    label={text} />
                  </Box>
                )
              })}
            </FormGroup>
          </FormControl>
        </Box>
    </Box>
  )
}




{/* <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
<FormGroup>
  <FormControlLabel
    control={
      <Checkbox checked={gilad} onChange={handleChange} name="gilad" />
    }
    label="Gilad Gray"
  />
  <FormControlLabel
    control={
      <Checkbox checked={jason} onChange={handleChange} name="jason" />
    }
    label="Jason Killian"
  />
  <FormControlLabel
    control={
      <Checkbox checked={antoine} onChange={handleChange} name="antoine" />
    }
    label="Antoine Llorca"
  />
</FormGroup>
</FormControl> */}


const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
})

const stagesWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '768px',
    width: '100%',
    alignSelf: 'center',
  },
})


const checkBoxGroup = (theme: Theme) => ({
  display: 'flex', 
  width: '100%', 
  maxWidth: '100%',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: '8px',
  // '& .MuiBox-root': {
  //   width: '49%',
  //   '& .MuiFormControlLabel-root': {
  //     margin: '0',
  //     display: 'inline-block',
  //     '& . MuiFormControlLabel-label': {
  //       fontWeight: '400',
  //     }
  //   }  
  // }
})

const checkBox = (theme: Theme) => ({
  width: '49%',
  '& .MuiFormControlLabel-root': {
    margin: '0',
    display: 'inline-block',
    '& . MuiFormControlLabel-label': {
      fontWeight: '400',
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
})