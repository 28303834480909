import { 
  Box, 
  Button,  
  Theme, 
  Typography, 
  useMediaQuery, 
  Card, 
  styled, 
  Badge,
  Divider,
  Link,
  Chip,
  Modal
} from '@mui/material'
import { useState } from 'react'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

import { Avatar } from './Avatar'
import { buildAssetUrl } from '../utilities/buildAssetUrl'
import { BgInDetails } from './bg-in-details'
import theme, { radiusOfComponents } from '../styles/theme'
import { useFromNow } from '../hooks/useDate'
import { useTranslation } from 'react-i18next'
import AlertDialog from './not-cms/ui-kits/alert-dialog/alert-dialog'
import { ProfileActionDropdown } from './ProfileActionDropdown'

// Icon
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { LinkedInIcon } from './icons/linkedIn';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate, generatePath } from 'react-router-dom'
import { PAGES } from '../router/AppRoutes'
import { ApiGraphQLTypes, ApiInputType } from 'recruticka-frontend-libs'
import { employeeSelector } from '../pages/employee-profile-edit'
import { EmployeeHeaderInformation } from '../components/not-cms/employee-edit-modal/employee-header-component'
import EditIcon from '@mui/icons-material/Edit'
import { useQueryClient } from 'react-query'
import { useDeleteProfile, useProfile, useUpdateProfile } from '../hooks/useProfile'
import { employeeSelector as employeeSelectorBase, SaveEmployeeValues, useUpdateEmployee } from '../hooks/useEmployee'

import { EmployeeEditLanguage } from '../components/not-cms/employee-edit-language'
import { LanguageComponent } from '../components/not-cms/employee-edit-modal/language-component'


interface IAlert {
  isOpen: boolean
  id?: string
}

const initialAlert: IAlert = {
  isOpen: false,
  id: undefined
}

enum BlockNames {
  CAREER_NARRATIVE = 'CAREER_NARRATIVE',
  WORK_EXPERIENCE = 'WORK_EXPERIENCE',
  EDUCATION = 'EDUCATION',
  BILLING = 'BILLING',
  LANGUAGES = 'LANGUAGES',
  PREFERENCES = 'PREFERENCES',
  SPECIALISE_IN = 'SPECIALISE_IN',
  CLIENTS = 'CLIENTS',
  HEADER = 'HEADER',
  HIDE_ME = 'HIDE_ME',
  MANAGING = 'MANAGING'
}
export enum TypeOfComponent {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export type EmployeeFields = ApiInputType<ApiGraphQLTypes['Employee'], typeof employeeSelector>

const CandidateProfileCard = ({profile, getEmployee } : { getEmployee : EmployeeFields, profile :  any}) => {

  const updateEmployeeMutation = useUpdateEmployee(employeeSelector)
  const updateProfileMutation = useUpdateProfile({ linkedin: true })
  const navigate = useNavigate();
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [openedModal, setOpenedModal] = useState<BlockNames>(BlockNames.HEADER)
  const [openModal, setOpenModal] = useState(false)
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [alert, setAlert] = useState<IAlert>(initialAlert)
  const deleteMutation = useDeleteProfile()
  const { refetch: refetchProfile } = useProfile()

  const publishedAtFromNow = useFromNow(getEmployee.publishedAt as Date | undefined)


  const showDeleteModal = (id: string) => {
    setAlert({
      isOpen: true,
      id
    })
  }

  const handleOpen = (blockName: BlockNames, ...args: any) => {
    setOpenModal(true)
    setOpenedModal(blockName)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const updateProfileLinkedin = async (linkedin = '') => {
    if (!getEmployee || !profile) {
      return
    }

    const { id } = profile

    const data = await updateProfileMutation.mutateAsync({
      id,
      linkedin,
    })

    if (getEmployee?.profile) {
      queryClient.setQueryData('employee', {
        getEmployee: {
          ...getEmployee,
          profile: {
            ...getEmployee.profile,
            linkedin: data.linkedin,
          },
        },
      })
    }
  }
  
  const saveEmployee = async (values: Partial<SaveEmployeeValues>) => {
    if (getEmployee) {
      const data = await updateEmployeeMutation.mutateAsync({
        values: {
          id: getEmployee.id,
          ...values,
        },
        employeeData: getEmployee
      })

      if (data) {
        queryClient.setQueryData('employee', {
          getEmployee: data,
        })
      }
    }

    await refetchProfile()
    // setOpenModal(false)
    handleClose()
  }

  return (
    <Card sx={profileCard}>
      <Box sx={backgroundImage}>
        {getEmployee?.coverImage?.url && (
          <BgInDetails content={getEmployee?.coverImage?.url} />
        )}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(20, 29, 36, 0.8)'
          }}
        />
      </Box>
      <Box sx={headAvatarStyle}>
        <Box sx={avatarBox}>
          <StyledBadge  
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            >
            <Avatar
              src={profile?.employee?.avatar?.url} sx={avatar}/>
          </StyledBadge>
        </Box>
        <Box sx={headContentWrapper}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Box sx={{ display: 'flex', gap: '32px', alignItems: 'center' }}>
              <Typography variant={'h5'} sx={profileName}>
                <>
                  {getEmployee.firstname} {getEmployee.lastname}
                </>
              </Typography>
              <Box onClick={() => handleOpen(BlockNames.HEADER)}>
                  <EditIcon sx={iconStyle} />
              </Box>
            </Box>
            {/* this field in not included in database */}
            {/* <Typography variant={'body1'} sx={profileDesc}>
                I specialize in UX/UI design, brand strategy. and Webflow development
            </Typography> */}

            <Box sx={profileBtnWrap}>
              <Box sx={profileBtnBox}>
                { getEmployee.cv &&
                  <Button
                    size='small'
                    startIcon={<RemoveRedEyeIcon />}
                    sx={buttonStyle}
                    variant='outlined'
                    onClick={() => {
                      window.open(buildAssetUrl(getEmployee.cv), '_blank', 'noopener,noreferrer')
                    }}
                  >
                      View CV
                  </Button>
                }
                {getEmployee.profile.linkedin && (
                <Button
                  size='small'
                  startIcon={<LinkedInIcon fill='#1E3296' />}
                  sx={buttonStyle}
                  href={getEmployee.profile.linkedin} 
                  target='_blank'
                  LinkComponent={Link}
                  variant='outlined'
                >
                    View LinkedIn
                </Button>
                )}
              </Box>

              <ProfileActionDropdown profileId={profile.id} showDeleteModalProp={(profileid) => {
                showDeleteModal(profileid);
              }} />

              {alert.isOpen && (
                <AlertDialog
                  isLoading={deleteMutation.isLoading}
                  isOpen={alert.isOpen}
                  deleteStyle={deletePopupStyle(theme)}
                  onClose={() => setAlert(initialAlert)}
                  onConfirm={async () => {
                    if (alert.id) {
                      setAlert(initialAlert)
                      await deleteMutation.mutateAsync()
                      window.location.reload()
                    }
                  }}
                  successMessage={'Yes delete my account'}
                  title={'Are you sure you want to delete your account?'}
                  description={'This can’t be undone. All your profile data will be lost.'}
                />
              )}
            </Box>

            <Box sx={profileContentBox}>
              <Typography variant={'subtitle2'} sx={{ mb: 1,}}>
                Career Narrative
              </Typography>
              <Typography variant={'body1'} sx={profileDesc}>
                {getEmployee.recruitRoles}
              </Typography>
            </Box>
            <Divider />
                
            {(getEmployee?.specializations || []).length > 0 && (
              <>
              <Box sx={[profileContentBox, infoStyle]}>
                <Typography variant={'subtitle2'} sx={{ mb: .5,}}>
                  Skills/Specialization
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {(getEmployee?.specializations || []).map(({ id, name }) => (
                      <Chip key={id} label={name} variant="outlined" color={'primary'} />
                    ))}
                </Box>
              </Box>
              <Divider />
              </>
            )}

            <Box sx={profileContentBox}>
              <Typography variant={'subtitle2'} sx={{ mb: 1,}}>
                Email
              </Typography>
              <Typography variant={'body1'} sx={profileDesc}>
                {getEmployee?.profile?.email}
              </Typography>
            </Box>
            <Divider />

            <Box sx={profileContentBox}>
              <Typography variant={'subtitle2'} sx={{ mb: 1.5,}}>
              Location
              </Typography>
              <Box sx={headLocationWrapper}>
                <LocationOnOutlinedIcon color={'primary'} />
                {getEmployee.currentCity && (
                  <>
                    <Typography noWrap={true} sx={location}>
                      {getEmployee.currentCity.name}, {getEmployee.currentCity.country.name}
                    </Typography>
                    {!!getEmployee.locationType && (
                      <Typography variant={'body2'} noWrap={true} sx={typeOfWorkSx}>
                        {t(`enum.JobLocationType.${getEmployee.locationType}`)}
                      </Typography>
                      )}
                  </>
                )}
              </Box>
            </Box>
            <Divider />

            <Box sx={profileContentBox} >
              <EmployeeEditLanguage
                  languages={getEmployee.languages}
                  onChange={() => handleOpen(BlockNames.LANGUAGES)}
                  type={TypeOfComponent.EDIT}
                />
            </Box>

            <Divider />

            <Box sx={additionalInfo}>
              <Link
                component='button'
                variant='body2'
                onClick={() => {
                  navigate(
                    generatePath(PAGES.PROFILE_EDIT, {
                      profileId: profile.id
                    })
                  )
                }}
              >
                <ArrowDownwardIcon /> Additional Info
              </Link>
            </Box>

            {/* {getEmployee.profile.linkedin && (
              <Link sx={linkWrapper} href={getEmployee.profile.linkedin} target='_blank'>
                LinkedIn
              </Link>
            )} */}

          </Box>
        </Box>
        {/* <Box sx={headPublish}>
          { matchSm &&
            <Button
              sx={{color: '#D32F2F', fontSize: '14px', gap: 1}}
              onClick={() => showDeleteModal(profile.id)}
            >
              <DeleteRoundedIcon sx={{height: '18px', width: '18px'}} />
              Delete profile
            </Button>
          }
          <Typography variant={'body2'} sx={publishInner}>
            Published: {publishedAtFromNow}
          </Typography>
        </Box> */}
      </Box>
      {/* {!matchSm &&
        <Box sx={deleteButton}>
          <Button
            sx={{color: '#D32F2F', fontSize: '14px', gap: 1}}
            onClick={() => showDeleteModal(profile.id)}
          >
            <DeleteRoundedIcon sx={{height: '18px', width: '18px'}} /> Delete profile
          </Button>
        </Box>
      }
      {alert.isOpen && (
        <AlertDialog
          isLoading={deleteMutation.isLoading}
          isOpen={alert.isOpen}
          deleteStyle={deletePopupStyle(theme)}
          onClose={() => setAlert(initialAlert)}
          onConfirm={async () => {
            if (alert.id) {
              setAlert(initialAlert)
              await deleteMutation.mutateAsync()
              window.location.reload()
            }
          }}
          successMessage={'Yes delete my account'}
          title={'Are you sure you want to delete your account?'}
          description={'This can’t be undone. All your profile data will be lost.'}
        />
      )} */}

      <Modal open={openModal} onClose={handleClose}>
        <Box sx={style}>
          {openedModal === BlockNames.HEADER && (
            <EmployeeHeaderInformation
              linkedin={getEmployee.profile.linkedin}
              isSaves={updateEmployeeMutation.isLoading}
              firstname={getEmployee.firstname}
              lastname={getEmployee.lastname}
              currentCity={getEmployee.currentCity}
              experience={getEmployee.experience}
              locationType={getEmployee.locationType}
              avatar={getEmployee.avatar}
              coverImage={getEmployee.coverImage}
              onClose={handleClose}
              onSave={async ({ linkedin, ...values }) => {
                await updateProfileLinkedin(linkedin)
                await saveEmployee(values)
              }}
            />
          )}

            {openedModal === BlockNames.LANGUAGES && (
              <LanguageComponent
                languages={getEmployee?.languages || []}
                onClose={handleClose}
                onSave={saveEmployee}
              />
            )}

        </Box>
      </Modal>
    </Card>
  )
}

const backgroundImage = (theme: Theme) => ({
  height: '180px',
  position: 'relative',
  backgroundColor: 'rgba(20, 29, 36, .8)',
  [theme.breakpoints.down('sm')]: {
  }
})

const deletePopupStyle = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& button': {
      width: '100%',
      marginLeft: 0,
      '&:nth-of-type(2)': {
        marginTop: 2
      }
    }
  }
})

const location = (theme: Theme) =>({
  fontSize: '14px'
})

const typeOfWorkSx = (theme: Theme) => ({
  overflow: 'unset',
  position: 'relative',
  marginLeft: '24px',
  '&:before': {
    position: 'absolute',
    content: '""',
    width: '4px',
    borderRadius: '50%',
    top: '8px',
    height: '4px',
    backgroundColor: theme.palette.primary.main,
    left: '-10px'
  }
})

const linkWrapper = (theme: Theme) => ({
  fontSize: '18px',
  color: '#90CAF9',
  [theme.breakpoints.down('lg')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  }
})

const headContentWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})

const headPublish = (theme: Theme) => ({
  ml: 'auto',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '-10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    '& button': {
      height: '30px',
      padding: '8px 0'
    },
  }
})
const publishInner = (theme: Theme) => ({
  color: 'rgba(255, 255, 255, 0.5)',
  mt: 'auto',
  mb: '-50px',
  [theme.breakpoints.down('sm')]: {
    mt: 'unset',
    mb: 'unset',
  }
})
const deleteButton = (theme: Theme) => ({
  marginTop: '-50px',
  marginBottom: '25px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: 0,
  }
})

const avatar = (theme: Theme) => ({
  height: '64px',
  width: '64px',
  backgroundColor: '#bdbdbd',
  [theme.breakpoints.down('sm')]: {
    // height: '62px',
    // width: '62px',
  }
})

const profileName = (theme: Theme) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px'
  }
})

const profileDesc = (theme: Theme) => ({

})

const headAvatarStyle = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  flexDirection: 'column',
  // marginTop: '-275px',
  // color: 'white',
  mb: '32px',
  px: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    // marginTop: '-215px',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    px: theme.spacing(2),
  }
})

const avatarBox = (theme: Theme) => ({
  mt: '-32px',
})

const headLocationWrapper = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    fontWeight: '600',
    '& span': {
      fontWeight: '400'
    },
    '& svg': {
      display: 'none'
    }
  }
})

const profileCard = (theme: Theme) => ({
  padding: 0,
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    padding: '0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '0',
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const profileBtnWrap = (theme: Theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
})
const profileBtnBox = (theme: Theme) => ({
  display: 'flex',
  gap: '10px',
  mt: theme.spacing(1.5),
})

const buttonStyle = (theme: Theme) => ({
  // backgroundColor: theme.palette.primary.main,
  // mx: 'auto',
})

const profileContentBox = (theme: Theme) => ({
  py: theme.spacing(2),
})

const infoStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const additionalInfo = (theme: Theme) => ({
  textAlign: 'center',
  paddingTop: '16px',
  '& .MuiLink-root': {
    display: 'inline-flex',
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    textTransform: 'uppercase',
  }
})

const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '864px',
  maxHeight: '90%',
  borderRadius: radiusOfComponents,
  overflowY: 'auto',
  outline: 'none',
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px'
  },
  [theme.breakpoints.down('md')]: {
    width: '700px',
    top: '55%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '340px'
  }
})

const iconStyle = (theme: Theme) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
})

export default CandidateProfileCard
