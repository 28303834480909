
import React, { useMemo, useState } from 'react'
import {
  Box,
  Theme,
  useMediaQuery,
  Grid,
  FormControl, InputLabel, MenuItem, Paper, ToggleButton, styled
} from '@mui/material'


import { generatePath, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { mainCompanyState, profileState } from '../../state/atoms/profileState'
import { useCreateJob } from '../../hooks/useJob'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '../ui-kits/button/button'
import { LoadingButton } from '@mui/lab'
import { PAGES } from '../../router/AppRoutes'
import { useQueryClient } from 'react-query'
import theme from '../../styles/theme'
import { ApiTypes } from 'recruticka-frontend-libs'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
  companyId: string
  setCompanyId: (id: string) => void
  setStatus: (status: ApiTypes.JobStatus) => void,
  status : ApiTypes.JobStatus
}

export const MyJobHeader = ({companyId, setCompanyId, setStatus, status}: Props) => {
  const queryClient = useQueryClient()
  const createJobMutation = useCreateJob({
    id: true,
  })
  const profile = useRecoilValue(profileState)
  const { id: mainCompanyId } = useRecoilValue(mainCompanyState)
  const navigate = useNavigate()
  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))

  const companies = useMemo(() => {
    return profile?.companies?.filter(company => company.status === ApiTypes.CompanyStatus.ACTIVE)
  },[profile?.companies?.map(company => company.status), profile?.companies?.length])

  const createJob = async () => {
    if (profile?.id && mainCompanyId) {
      const { id } = await createJobMutation.mutateAsync({
        companyId: mainCompanyId,
        title: 'New job',
      })
      queryClient.removeQueries(['job'])
      navigate(
        generatePath(PAGES.JOB_EDIT, {
          profileId: profile.id,
          jobId: id,
        }),
      )
    }
  }

  const [company, setCompany] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setCompany(event.target.value as string);
    setStatus(event.target.value as ApiTypes.JobStatus);
  };

  return (
    <>
    {!!companies?.length &&
      <Grid container spacing={1} >
        <Grid item sm={12} xs={12} sx={{ display: 'flex', gap: '24px', justifyContent: 'flex-end'}}> 
          <Box sx={leftFieldBox}>
            <Box sx={fieldBoxWrap}>
                <Box
                  component='form'
                  sx={fieldBox}>
                    <FormControl sx={companySelectStyle} fullWidth={matchSm}>
                      <InputLabel id='demo-simple-select-label'>Company</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={companyId}
                        label='Age'
                        onChange={handleChange}
                        defaultValue={mainCompanyId}
                      >
                        {
                          companies?.map(company => (
                            <MenuItem value={company.id} key={company.id}>
                              {company.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                </Box>
             
              <Box
                component='form'
                sx={fieldBox}>
                  <FormControl sx={companySelectStyle} fullWidth={matchSm}>
                    <InputLabel id='demo-simple-select-label'>Status</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={status}
                      label='Age'
                      onChange={handleChange}
                      defaultValue={status}
                    >
                      <MenuItem value={ApiTypes.JobStatus.ACTIVE} >Active</MenuItem>
                      <MenuItem value={ApiTypes.JobStatus.DRAFT} >Draft</MenuItem>
                      <MenuItem value={ApiTypes.JobStatus.ARCHIVED} >Archive</MenuItem>
                    </Select>
                  </FormControl>
              </Box>
            </Box>

            {/* <LoadingButton
              loading={createJobMutation.isLoading}
              variant={matchSm ? 'outlined' : 'contained'}
              startIcon={
                <AddIcon
                  sx={
                    !companies?.length ? {'& path': {fill: 'rgba(0, 0, 0, 0.26)!important'}} : {}
                  }
                />
              }
              size={'large'}
              onClick={createJob}
              disabled={!companies?.length}
            >
              Create a new job
            </LoadingButton> */}
            
          </Box>
        </Grid>
      </Grid>
    }
    </>
  )
}

const companySelectStyle = (theme: Theme) => ({
  width: '300px',
  m: 1,
  [theme.breakpoints.down('md')]:{
    width: '200px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})

const leftFieldBox = (theme: Theme) => ({
  width: '100%',
  display: 'flex',
  gap: '16px',
  maxWidth: '600px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '24px',
    gap: '0',
  }
});


const fieldBox = (theme: Theme) => ({
  width: '25%', 
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
  '& .MuiFormControl-root': {
    // height: '48px',
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2),
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  }
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0 , 1),
    height: '30px',
    width: '30px',
    backgroundColor: '#fff',
    border: '1px solid #E2E2EA !important',
    transition: '0.2s ease-in-out',
    '&:not(:first-of-type)': {
      marginRight: '0',
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.primary.contrastText,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& svg path': {
          fill: theme.palette.primary.contrastText,
        },
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& svg path': {
        fill: theme.palette.secondary.contrastText,
      },
    }
  },
  [theme.breakpoints.down('sm')]: { 
    display: 'none',
   },
}));

const fieldBoxWrap = (theme: Theme) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'end',
  width:'100%',
  [theme.breakpoints.down('xs')]: {
    width:'100%',
    flexWrap: 'wrap',
    gap: '0',
  }
});

