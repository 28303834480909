import { useRecoilValue } from 'recoil'
import { ApiTypes } from 'recruticka-frontend-libs'
import { profileState } from '../state/atoms/profileState'
import SignUpEmployer from './sign-up-employer'
import SignUpForEmployee from './sign-up-for-employee'

export const SetupProfile = () => {
  const profile = useRecoilValue(profileState)

  if (profile?.role === ApiTypes.Role.EMPLOYEE) {
    return <SignUpForEmployee />
  }

  if (profile?.role === ApiTypes.Role.EMPLOYER) {
    return <SignUpEmployer />
  }

  return null
}
