import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { ApiTypes } from 'recruticka-frontend-libs'

export const ExperienceSelect = () => {
  const { t } = useTranslation()

  return (
    <Field name={'experience'} component={Select} placeholder={t('formFields.experiencePlaceholder')} fullWidth>
      {Object.values(ApiTypes.Experience).map((value) => (
        <MenuItem value={value} key={value}>
          {t(`enum.Experience.${value}`)}
        </MenuItem>
      ))}
    </Field>
  )
}
