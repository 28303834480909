// import * as React from 'react';
import React, { useState, ReactNode, SyntheticEvent, useRef  } from 'react'
import { CompanyProfileHeader } from '../components/not-cms/company-profile-header'

import { Content } from '../components/common-files/common-styles'
import { 
  Alert, 
  Box, 
  CircularProgress, 
  Snackbar, 
  Stack,
  Theme, 
  Typography, 
  Divider, 
  Card,
  useMediaQuery,
  Tabs,
  Tab,
  createTheme,
  ThemeProvider,
  AlertTitle
} from '@mui/material'
import { $enum } from 'ts-enum-util'

import { CompanyInformation } from '../components/not-cms/company-information'
import { SetLocations } from '../components/not-cms/set-locations'
import { SetupPerks } from '../components/not-cms/setup-perks'
import TempWrapperNew from '../components/tempWrapperNew'
import { ApiTypes } from 'recruticka-frontend-libs'
import { authMutation, useAuthQueryRequest } from '../services/api'
import { useQueryClient } from 'react-query'
import {
  CompanyUpdateFields,
  companyUpdateSelector,
  useOpenCompanyPage,
  usePublishCompany,
  useUpdateCompany,
  CompanyFields,
} from '../hooks/useCompany'
import { useRecoilState, useRecoilValue } from 'recoil'
import { profileCompanyId, profileState } from '../state/atoms/profileState'
import { CompanyProfileCoverImage } from '../components/not-cms/CompanyProfileCoverImage'
import { CompanyProfileGallery } from '../components/not-cms/CompanyProfileGallery'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import Yup, { RatingValidation, ShortTextareaValidation, TextareaValidation, TextValidation } from '../validation'
import { useUpdateProfile } from '../hooks/useProfile'
import { MAX, MIN } from '../components/not-cms/employer-steps/amount-people'
import AlertDialog from '../components/not-cms/ui-kits/alert-dialog/alert-dialog'
import { SistersCompany } from '../components/not-cms/sisters-company'
// import { useParams } from 'react-router-dom'
import { useCreateOrUpdateSalary } from '../hooks/useCreateOrUpdateSalary'
import { CompanySocialNetworks } from '../components/not-cms/company-social-networks'
import { SalaryFields, salarySelector } from '../components/not-cms/employer-steps/budget-information'
import { useParams } from 'react-router-dom'
import theme from '../styles/theme'

import { LookingFor } from '../components/not-cms/profile-company-information-tabs/looking-for'
import { Location } from '../components/not-cms/profile-company-information-tabs/location'
import { BudgetForBasicSalaries } from '../components/not-cms/profile-company-information-tabs/budget-for-basic-salaries'
import { CommissionStructure } from '../components/not-cms/profile-company-information-tabs/commission-structure'
import { InterviewProcessStages } from '../components/not-cms/profile-company-information-tabs/interview-process-stages'
import { CultureOfBusiness } from '../components/not-cms/profile-company-information-tabs/culture-of-business'
import { CompanyRatings } from '../components/not-cms/profile-company-information-tabs/companyRatings'
import { Recruitment } from '../components/not-cms/profile-company-information-tabs/Recruitment'
import SidebarInternal from '../components/SidebarInternal'

// Footer Button
import FooterButtonClient from '../components/footerButtonClient'


export type ValidationError = {
  cover?: string
  logoId?: string
  shortDescription?: string
  foundedAt?: string
  name?: string
  offices?: string
  description?: string
}

const perkOptions : any = {
  '13' : ApiTypes.CompanyPerkType.LEARNING_DEVELOPMENT,
  '14' : ApiTypes.CompanyPerkType.TIME_OFF,
  '15' : ApiTypes.CompanyPerkType.FLEXIBLE_WORKING,
  '16' : ApiTypes.CompanyPerkType.FINANCIAL_BENEFITS,
  '17' : ApiTypes.CompanyPerkType.INCLUSION_DIVERSITY,
  '18' : ApiTypes.CompanyPerkType.HEALTS_WELLNESS,
  '19' : ApiTypes.CompanyPerkType.OFFICE_ENVIRONMENT,
  '20' : ApiTypes.CompanyPerkType.MENTAL_HEALTS_SUPPORT,
};

const CompanyProfileSetup = () => {

  const matchSm = useMediaQuery(theme.breakpoints.down('sm'))
  const profile = useRecoilValue(profileState)
  const queryClient = useQueryClient()
  const updateCompanyMudation = useUpdateCompany()
  const publishMutation = usePublishCompany()
  const [, setProfileCompanyId] = useRecoilState(profileCompanyId)
  const [publishErrors, setPusblishErrors] = useState<ValidationError>({})
  const [serverErrors, setServerErrors] = useState<boolean>(false)
  const { t } = useTranslation()
  const updateProfileMutation = useUpdateProfile({ linkedin: true })
  const { openCompanyPage } = useOpenCompanyPage()
  const [profileLiveModal, setProfileLiveModal] = useState(false)
  const createOrUpdateSalaryMutation = useCreateOrUpdateSalary(salarySelector)
  const { companyId } = useParams()
  const sectionRefs = useRef<HTMLDivElement[]>([]); 
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0)
  const [perkActiveTab, setPerkActivetab] = React.useState<ApiTypes.CompanyPerkType>(ApiTypes.CompanyPerkType.LEARNING_DEVELOPMENT);

  const {
    data: companyData,
    isLoading: companyDataLoading,
    isRefetching: companyDataRefetching,
  } = useAuthQueryRequest(
    ['company', companyId],
    {
      getCompany: [{ where: { id: companyId } }, companyUpdateSelector],
    },
    {
      enabled: companyId !== undefined,
      onSuccess(data) {
        setProfileCompanyId(data.getCompany.id)
      },
    },
  )

  function nextStep(index: number) {
    const nextIndex : number = (!companyData?.getCompany.isMain && index == 10) ? index + 1 : index;
    if(currentMenuIndex >= 13 && currentMenuIndex <= 19) {
      setCurrentMenuIndex(currentMenuIndex+1)
      setPerkActivetab(perkOptions[currentMenuIndex+1]);
    } else if(currentMenuIndex == 20 && nextIndex == 14) {
      srollToElement(21)
      setCurrentMenuIndex(21)
      setPerkActivetab(perkOptions[13]);
    } else {
      srollToElement(nextIndex)
      setCurrentMenuIndex(nextIndex)
    } 
  }

  function srollToElement(index : number) {
    setCurrentMenuIndex(index)
    const offset = 165; 
    const element = sectionRefs.current[index];

    if (element) {
      const offsetPosition = element.offsetTop + offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  

  function handleSideDropDown(index : number) {

      if(index >= 13 && index <= 20) {
        setPerkActivetab(perkOptions[index])
      }
      
      srollToElement(index);
  }

  function handlePerkTab(event: SyntheticEvent, subTab: ApiTypes.CompanyPerkType) {
      setPerkActivetab(subTab);
  }

  const saveCompany = async (values: CompanyUpdateFields) => {
    if (!companyData?.getCompany) {
      return
    }

    if  (!values?.basicSalary?.id){
      const data = await createOrUpdateSalaryMutation.mutateAsync(values.basicSalary as SalaryFields)
      await authMutation({
        updateCompany: [
          {
            id: companyData?.getCompany.id,
            input: {
              basicSalary: {
                connect: {
                  id: data.id,
                },
              },
            },
          },
          {
            id: true,
          },
        ],
      })
    }


    /* const { id, ...companyFields } = companyData.getCompany
    const { id: valuesId, offices, perks, logo, ...input } = values

    const hasDiff =
      Object.values(diff(companyFields, input)).filter((v) => v).length > 0

    if (!hasDiff) {
      return companyFields
    } */

    // if (!values?.basicSalary?.id) {
    //    const data = await createOrUpdateSalaryMutation.mutateAsync(values.basicSalary as SalaryFields)
    //     await authMutation({
    //       updateCompany: [
    //        {
    //         id: companyData?.getCompany.id,
    //         input: {
    //           basicSalary: {
    //            connect: {
    //             id: data.id,
    //              },
    //            },
    //           },
    //         },
    //         {
    //           id: true,
    //         },
    //       ],
    //     })
    // }

    const data = await updateCompanyMudation.mutateAsync({
      values,
      oldValues: companyData.getCompany,
    })

    queryClient.setQueryData(['company', companyId], {
      getCompany: data,
    })

    queryClient.refetchQueries('profile')

    // srollToElement(2)
  }

  const onPublish = async () => {
    if (companyData?.getCompany.id) {
      try {
        const { status } = await publishMutation.mutateAsync(companyData.getCompany.id)

        queryClient.setQueryData(['company', companyId], {
          getCompany: {
            ...queryClient.getQueryData<{ getCompany: CompanyFields }>(['company', companyId])?.getCompany,
            status,
          },
        })

        setProfileLiveModal(true)
      } catch (e: any) {
        console.log(e.response?.errors);
        const errorss = e.response?.errors[0]

        if (e.response.errors[0].message === 'VALIDATION_ERROR') {
          setPusblishErrors(
            errorss.extensions.errors.reduce((acc: any, item: any) => {
              acc[item.field] = item.field // a, b, c
              return acc
            }, {}),
          )
          setServerErrors(true)
        }
      }
    }
  }

  // const getPublishError = (error: ValidationError) => {
  //   let field = t(`companyForm.${error.field as keyof typeof translation.companyForm}`)
  //   let errorss = 'Field is required'
  //
  //   if (error.field.startsWith('offices')) {
  //     field = t('companyForm.offices')
  //     errorss = 'Country is required'
  //   }
  //
  //   if (error.field === 'cover') {
  //     errorss = 'Cover image or Cover video should be filled in.'
  //   }
  //
  //   return { field, errorss }
  // }

  const updateProfileLinkedin = async (linkedin = '') => {
    if (!companyData?.getCompany) {
      return
    }

    const data = await updateProfileMutation.mutateAsync({ id: profile.id, linkedin })

    if (companyData.getCompany.profile) {
      queryClient.setQueryData(['company', companyId], {
        getCompany: {
          ...companyData.getCompany,
          profile: {
            ...companyData.getCompany.profile,
            linkedin: data.linkedin,
          },
        },
      })
    }
  }
  
  if (companyDataLoading || companyDataRefetching || !companyData?.getCompany?.id) {
    return <CircularProgress />
  }
  
  return (
    <TempWrapperNew>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          id: companyData.getCompany.id,
          coverImage: companyData.getCompany.coverImage,
          coverVideo: companyData.getCompany.coverVideo,
          logo: companyData.getCompany.logo,
          name: companyData?.getCompany?.name?.trimStart() || '',
          description: companyData.getCompany.description || '',
          foundedAt: companyData.getCompany.foundedAt || null,
          size: companyData.getCompany.size || ApiTypes.CompanySize.LESS_10,
          linkedin: companyData.getCompany.profile.linkedin || '',
          website: companyData.getCompany.website || '',
          shortDescription: companyData.getCompany.shortDescription || '',
          offices: companyData.getCompany.offices || [],
          gallery: companyData.getCompany.gallery || [],
          perks: companyData.getCompany.perks || [],
          jobTypes: companyData.getCompany.jobTypes,
          workFromHome: companyData.getCompany.workFromHome || false,
          recruitmentsFocus: companyData.getCompany.recruitmentsFocus,
          workFromHomeDays: companyData.getCompany.workFromHomeDays || 1,
          considerPeopleFromOtherSector:
            companyData.getCompany.considerPeopleFromOtherSector || ApiTypes.ConsiderPeopleFromOtherSector.MAYBE,
          lookingForPeople: companyData.getCompany.lookingForPeople || 0,
          hiringInCities: companyData.getCompany.hiringInCities || [],
          commissionAndIncentives: companyData.getCompany.commissionAndIncentives || '',
          commissionAndIncentivesFiles: companyData.getCompany.commissionAndIncentivesFiles || [],
          inverviewStages: companyData.getCompany.inverviewStages || [],
          cultureOfBusiness: companyData.getCompany.cultureOfBusiness || '',
          basicSalary: {
            id: companyData.getCompany?.basicSalary?.id,
            from: companyData.getCompany?.basicSalary?.from || 0,
            to: companyData.getCompany?.basicSalary?.to || 0,
            schedule: companyData.getCompany?.basicSalary?.schedule || ApiTypes.SalaryPeriod.MONTH,
            currency: companyData.getCompany?.basicSalary?.currency || null,
          },
          companyRatingsFields: companyData.getCompany?.companyRatings || [],
          companyLocations: companyData.getCompany.companyLocations || [],
          locationFiles: companyData.getCompany?.locationFiles || [],
          budgetForBasicSalariesFiles: companyData.getCompany?.budgetForBasicSalariesFiles || [],
          cultureOfBusinessFiles: companyData.getCompany?.cultureOfBusinessFiles || [],
          facebook: companyData.getCompany?.facebook || '',
          instagram: companyData.getCompany?.instagram || '',
          twitter: companyData.getCompany?.twitter || '',
        }}
        onSubmit={async ({ linkedin, ...values }, { setSubmitting }) => {
          await updateProfileLinkedin(linkedin)
          await saveCompany(values)
          setSubmitting(false)
        }}
      >
        {({ touched}) => (
          <Form>
              <Content sx={contentWrapper}>

              <CompanyProfileHeader
                companyId={companyId}
                companyStrength={companyData.getCompany.strength}
                isCompanyActive={companyData.getCompany.status === ApiTypes.CompanyStatus.ACTIVE}
                onPublish={onPublish}
              />
              {/* Anis:  ON add location it break the app */}
              <Snackbar
                  autoHideDuration={10000}
                  open={serverErrors}
                  anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  onClose={() => {
                    setPusblishErrors({})
                    setServerErrors(false)
                  }}
                  sx={{ top: '120px !important' }}
                >
                  <Stack sx={{ width: '100%', flexDirection: 'column', gap: 2 }} spacing={2}>
                      <Alert severity='error'>
                        <AlertTitle>Some fields are required</AlertTitle>
                        {/* {getPublishError(error).errors}*/}
                      </Alert>
                  </Stack>
                </Snackbar>
           

                <Box sx={tabWrapper}>
                  
                  { !matchSm &&
                      <SidebarInternal isMainCompany={companyData?.getCompany.isMain} currentMenuIndex={currentMenuIndex} handleSideDropDown={handleSideDropDown} />
                  }

                  <Box sx={tabContentBox}>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[0] = ref as HTMLDivElement)}>
                          <CompanyProfileCoverImage
                            coverError={publishErrors.cover}
                            title='Cover Image'
                            subTitle='Select asset type to show as your cover:'
                            note='Your Image Cover will still be used in search results and thumbnails'
                          />
                          <FooterButtonClient srollToElement={() => nextStep(1)} />
                      </Card>
                    
                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[1] = ref as HTMLDivElement)}>
                        <CompanyInformation isMain={companyData.getCompany.isMain} publishErrors={publishErrors} />
                        <FooterButtonClient srollToElement={() => nextStep(2)} />
                      </Card>  

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[2] = ref as HTMLDivElement)}>
                        <Typography sx={(theme) => ({
                            marginBottom: theme.spacing(3)
                          })} variant={'h2'}>{t('companyInformations.lookingFor')}</Typography>
                        <Divider sx={(theme) => ({
                            marginBottom: theme.spacing(4)
                          })} />

                        <LookingFor />
                        <FooterButtonClient srollToElement={() => nextStep(3)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[3] = ref as HTMLDivElement)}>
                        <Typography sx={(theme) => ({
                          marginBottom: theme.spacing(3),
                          [theme.breakpoints.down('sm')]: {
                            marginBottom: theme.spacing(1.5),
                          },
                        })} variant={'h2'}>{t('companyInformations.location')}</Typography>
                        <Divider sx={(theme) => ({
                          marginBottom: theme.spacing(4)
                        })} />

                        <Location /> 
                        <FooterButtonClient srollToElement={() => nextStep(4)} />
                      </Card>
                      
                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[4] = ref as HTMLDivElement)}>
                        <Typography sx={(theme) => ({
                          marginBottom: theme.spacing(3),
                          [theme.breakpoints.down('sm')]: {
                            marginBottom: theme.spacing(1.5),
                          },
                        })} variant={'h2'}>{t('companyInformations.basicSalary')}</Typography>
                        <Divider sx={(theme) => ({
                          marginBottom: theme.spacing(4.5)
                        })} />

                        <BudgetForBasicSalaries />
                        <FooterButtonClient srollToElement={() => nextStep(5)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[5] = ref as HTMLDivElement)}>
                          <Typography sx={(theme) => ({
                          marginBottom: theme.spacing(3),
                          [theme.breakpoints.down('sm')]: {
                            marginBottom: theme.spacing(1.5),
                          },
                        })} variant={'h2'}>{t('formFields.recruitmentFocusTitle') }</Typography>
                        <Divider sx={(theme) => ({
                          marginBottom: theme.spacing(4.5)
                        })} />

                        <Recruitment />
                        <FooterButtonClient srollToElement={() => nextStep(6)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[6] = ref as HTMLDivElement)}>
                        <Typography sx={(theme) => ({
                          marginBottom: theme.spacing(3),
                          [theme.breakpoints.down('sm')]: {
                            marginBottom: theme.spacing(1.5),
                          },
                        })} variant={'h2'}>{t('companyInformations.comissionStructureIncentives')}</Typography>
                        <Divider />
                        
                        <CommissionStructure />
                        <FooterButtonClient srollToElement={() => nextStep(7)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[7] = ref as HTMLDivElement)}>
                          <Typography sx={(theme) => ({ 
                            marginBottom: theme.spacing(3),
                            [theme.breakpoints.down('sm')]: {
                              marginBottom: theme.spacing(1.5),
                            },
                          })} variant={'h2'}>{t('companyInformations.interviewSteps')}</Typography>
                          <Divider sx={(theme) => ({
                            marginBottom: theme.spacing(3),
                            [theme.breakpoints.down('sm')]: {
                              marginBottom: theme.spacing(2.5),
                            },
                          })} />

                          <InterviewProcessStages />
                          <FooterButtonClient srollToElement={() => nextStep(8)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[8] = ref as HTMLDivElement)}>
                          <Typography sx={(theme) => ({ 
                            marginBottom: theme.spacing(3), 
                            [theme.breakpoints.down('sm')]: {
                              marginBottom: theme.spacing(1.5),
                            },
                          })} variant={'h2'}>{t('companyInformations.cultureOfBusiness')}</Typography>
                          <Divider sx={(theme) => ({
                            marginBottom: theme.spacing(4.5)
                          })} />
                          <CultureOfBusiness />
                          <FooterButtonClient srollToElement={() => nextStep(9)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[9] = ref as HTMLDivElement)}>
                          <Typography sx={(theme) => ({ 
                            marginBottom: theme.spacing(3),
                            [theme.breakpoints.down('sm')]: {
                              marginBottom: theme.spacing(1.5),
                            },
                            })} variant={'h2'}>Reviews</Typography>
                          <Divider sx={(theme) => ({
                            marginBottom: theme.spacing(4.5)
                          })} />
                          <CompanyRatings />
                          <FooterButtonClient srollToElement={() => nextStep(10)} />
                      </Card>

                      {companyData?.getCompany.isMain &&
                          <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[10] = ref as HTMLDivElement)}>
                            <SistersCompany />
                            <Box
                              sx={[
                                publishErrors?.offices
                                  ? {
                                      borderRadius: '14px',
                                      border: '1px solid rgb(211 47 47)',
                                    }
                                  : {},
                                { width: '100%' },
                              ]}
                            > 
                            </Box>
                            <FooterButtonClient srollToElement={() => nextStep(11)} />

                          </Card>
                      }
                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[11] = ref as HTMLDivElement)}>
                        <SetLocations />
                        <FooterButtonClient srollToElement={() => nextStep(12)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[12] = ref as HTMLDivElement)}>
                        <CompanyProfileGallery title={'Gallery'} subTitle={'Upload images and video assets'} />
                        <FooterButtonClient srollToElement={() => nextStep(13)} />
                      </Card>

                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[13] = ref as HTMLDivElement)}>

                        { matchSm &&
                          <ThemeProvider theme={tabsTheme}>
                            <Tabs
                              orientation='vertical'
                              variant='scrollable'
                              value={perkActiveTab}
                              onChange={handlePerkTab}
                              aria-label='Vertical tabs example'
                              sx={tabsStyle}
                            > 
                              { $enum(ApiTypes.CompanyPerkType)
                                .getKeys()
                                .map((type) => <Tab key={type} value={type} sx={tabsItemStyle} label={ApiTypes.CompanyPerkType[type]} /> )
                              }
                            </Tabs> 
                            </ThemeProvider>
                        } 
                        <SetupPerks scrollItems={{ id: 'Perks', label: 'Learning & Development' }} activeTab={perkActiveTab} />
                        <FooterButtonClient srollToElement={() => nextStep(14)} />
                      </Card>
                          
                      <Card sx={cardWraper} ref={(ref) => (sectionRefs.current[21] = ref as HTMLDivElement)}>
                        <CompanySocialNetworks />
                        <FooterButtonClient isSave={true} srollToElement={() => nextStep(0)}  />
                      </Card>
                    
                  </Box>
                </Box>
              </Content>
          </Form>
        )}
      </Formik>
      <AlertDialog
        isOpen={profileLiveModal}
        onClose={() => setProfileLiveModal(false)}
        onConfirm={async () => {
          if (companyData.getCompany.slug) {
            openCompanyPage(companyData.getCompany.slug)
            setProfileLiveModal(false)
          }
        }}
        description={'Congratulations! Your profile is now visible on the recruiticka platform for future employees!'}
        successMessage={'View profile'}
      />
    </TempWrapperNew>
  )
}

const validationSchema = Yup.object().shape({
  name: TextValidation.required('Company Name field is required'),
  description: TextareaValidation.nullable(),
  foundedAt: Yup.date().nullable().max(new Date()),
  website: TextValidation.url().nullable(),
  linkedin: TextValidation.url().nullable(),
  shortDescription: ShortTextareaValidation.nullable(),
  offices: Yup.array().of(
    Yup.object().shape({
      address: TextValidation.nullable(),
      isMain: Yup.boolean(),
      city: Yup.object({
        id: TextValidation,
      }).typeError('Field is required'),
    }),
  ),

  lookingForPeople: Yup.number().min(MIN).max(MAX).required(),
  jobTypes: Yup.array().min(1).max(5).required('Job type field is required'),
  workFromHome: Yup.bool().required('Work from home field is required'),
  workFromHomeDays: Yup.number().when('workFromHome', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
  considerPeopleFromOtherSector: Yup.string().required(),
  hiringInCities: Yup.array(),
  basicSalary: Yup.object({
    from: Yup.number().required('From field is required'),
    to: Yup.number().required('To field is required'),
    schedule: Yup.string().required('Schedule field is required'),
    currency: Yup.object().required('Currency field is required'),
  }),
  companyRatingsFields: RatingValidation,
  commissionAndIncentives: TextareaValidation.required('Commission and incentive field is required'),
  cultureOfBusiness: TextareaValidation.required('Culture Of business field is required'),
  inverviewStages: Yup.array().min(1),
  perks: Yup.array().of(
    Yup.object({
      description: TextValidation.nullable(),
      textBlocks: Yup.array().of(
        Yup.object({
          title: TextValidation.nullable(),
          description: TextareaValidation.nullable(),
        }),
      ),
    }),
  ),
  facebook: TextValidation.url().nullable(),
  instagram: TextValidation.url().nullable(),
  twitter: TextValidation.url().nullable(),
})

const cardWraper = (theme: Theme) => ({
  marginBottom: 6,
  paddingBottom: '32px !important',
  [theme.breakpoints.down('sm')]: {
    marginBottom: 3,
  }
})

const contentWrapper = (theme: Theme) => ({
  flexDirection: 'column',
  marginBottom: '0',
  gap: '32px',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(6),
  },
})

const tabWrapper = (theme: Theme) => ({
 display: 'flex', 
 alignItems: 'start',
 height: '100%',
 width: '100%',
 flexDriection: 'row',
 gap: '24px',
})


const tabContentBox = (theme: Theme) => ({
  width: '82%',
  height: '100%',
  position: 'relative',
  '& > .MuiCard-root': {
    paddingBottom: theme.spacing(18),
  },
  [theme.breakpoints.down(1250)]: {
    width: '75%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
})

const tabsStyle = (theme: Theme) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'auto',
    width: '100%',
    marginBottom: theme.spacing(4),
  },
})

const tabsItemStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '6px',
  color: theme.palette.text.primary,
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '24px',
  alignItems: 'start',
  textTransform: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  // whiteSpace: 'nowrap',
  textAlign: 'initial',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(2),
  }
})

const tabsTheme = createTheme(theme, {
  ...theme,
  components: {
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: theme.spacing(1),
          [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
          },
        },
        indicator: {
          display: 'none',
        },
      },
    },
  },
})


export default CompanyProfileSetup
