import { Box, Theme, Typography } from '@mui/material'
import { siteAllJobsPage, siteUrl } from '../../../config/urls'
import { Button } from '../../ui-kits/button/button'

interface Props {
  isEmployer?: boolean
}

export const Final = ({ isEmployer }: Props) => {
  const allJobsLink = siteAllJobsPage
  const siteLink = siteUrl

  return (
    <Box sx={wrapper}>
      <Box className='stepFieldWrapper'>
        <Typography variant={'h5'}>Thanks for applying</Typography>
        <Typography variant={'body1'} sx={description}>
          We will review your application and you’ll be notified once it’s approved or declined. It usually takes a few
          minutes
        </Typography>
      </Box>
      <Box sx={{justifyContent:'center !important', }} className='stepFooter'>
        <Box sx={{justifyContent:'center !important' }}>
          {!isEmployer && (
            <Button href={allJobsLink} size={'medium'} sx={finalButton}>
              Ok
            </Button>
          )}
          {isEmployer && (
            <Button href={siteLink} size={'medium'} sx={finalButton}>
              Ok
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}
const finalButton = (theme: Theme) => ({
  mx: 'auto',
  [theme.breakpoints.down('sm')]:{
    height: '41px',
    paddingX: '28px'
  }
})

const wrapper = (theme: Theme) => ({
  textAlign: 'center',
  mt: theme.spacing(4),
  display: 'block',
  minHeight: '131px',
  maxHeight: '178px',
  maxWidth: '400px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]:{
    mt: 'unset',
    maxHeight: 'unset',
  }
})

const description = (theme: Theme) => ({
  mt: '16px',
  mb: '24px',
  [theme.breakpoints.down('sm')]:{
  }
})
