import { Formik, Form, Field } from 'formik'
import Yup from '../../../validation'
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'
import { ApiGraphQLTypes, ApiInputType, ApiSelector, ApiTypes } from 'recruticka-frontend-libs'
import { ChangeEvent } from 'react'
import { SliderField } from '../../ui-kits/slider-field/slider-field'
import { useTranslation } from 'react-i18next'
import { Salary } from '../../form-fields/Salary'
import { useDefaultCurrency } from '../../../hooks/useCurrency';
import { SetOptional } from 'type-fest'
import { useCurrencyFormatter } from '../../../hooks/useCurrencyFormatter'
import theme from '../../../styles/theme'
import FooterButton from '../../footerButton'

const salarySelector = ApiSelector('Salary')({
  id: true,
  from: true,
  to: true,
  schedule: true,
  currency: {
    id: true,
    name: true,
    symbol: true,
  },
})

type SalaryFields = SetOptional<ApiInputType<ApiGraphQLTypes['Salary'], typeof salarySelector>, 'id'>

interface ManagingTeamFields {
  managedTeam?: boolean,
  teammatesCount?: number,
  teamAvgSalary?: SalaryFields
}

interface Props extends ManagingTeamFields {
  onSave: (values: ManagingTeamFields) => void
  fieldsPrefix?: string
}

export const EmployeeManage = ({ onSave, managedTeam, teamAvgSalary, teammatesCount }: Props) => {
  const smWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation();
  const defaultCurrency = useDefaultCurrency();

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...teamAvgSalary,
        from: teamAvgSalary?.from || 0,
        to: teamAvgSalary?.to || 0,
        schedule: teamAvgSalary?.schedule || ApiTypes.SalaryPeriod.MONTH,
        currency: teamAvgSalary?.currency || defaultCurrency || null,
        managedTeam: managedTeam || false,
        teammatesCount: teammatesCount || 0,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const { managedTeam, teammatesCount, ...teamAvgSalary } = values;
        const currency = teamAvgSalary.currency;

        if (currency) {
          await onSave({
            managedTeam,
            teammatesCount,
            teamAvgSalary: {
              ...teamAvgSalary,
              currency
            }
          })
        }

        setSubmitting(false)
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        const maxMonth = 250000;
        const maxYear = 3000000;
        const max = values.schedule === ApiTypes.SalaryPeriod.MONTH ? maxMonth : maxYear;
        const maxFormatted = useCurrencyFormatter(max, values.currency?.id)

        return (
          <Form>
            <Box sx={cardWrapper}>
              <Box>
                <Typography  sx={(theme) => ({
                    marginBottom: theme.spacing(3)
                  })} variant={'h2'}>Have you managed a team?</Typography>
                  <Divider />
              </Box>
              <Box>
                <Field
                  component={RadioGroup}
                  name={'managedTeam'}
                  sx={{ flexDirection: 'row', gap: '43px' }}
                  onChange={(event: ChangeEvent<HTMLInputElement>, value: string) => {
                    setFieldValue('managedTeam', value === 'true')
                  }}
                >
                  <FormControlLabel
                    checked={values.managedTeam === true}
                    value={true}
                    control={<Radio disabled={isSubmitting} />}
                    label={t('yes')}
                    disabled={isSubmitting}
                  />
                  <FormControlLabel
                    checked={values.managedTeam === false}
                    value={false}
                    control={<Radio disabled={isSubmitting} />}
                    label={t('no')}
                    disabled={isSubmitting}
                  />
                </Field>
              </Box>
                {values.managedTeam &&
                  <>
                    <Box sx={{ paddingY: '20px' }}>
                      <Typography variant={'subtitle2'} sx={sliderTitle}>How many teammates have you managed?</Typography>
                      <SliderField
                        min={0}
                        max={100}
                        sx={[sliderStyles, { mb: 3, }]}
                        value={values.teammatesCount}
                        valueLabelDisplay="on"
                        onChange={(event, value) => setFieldValue('teammatesCount', value)}
                        marks={[
                          {
                            value: 0,
                            label: '0'
                          },
                          {
                            value: 100,
                            label: '100',
                          }
                        ]}
                      />
                      <Divider sx={{ marginTop: '30px' }} />
                    </Box>
                    <Box>
                      <Typography variant={'subtitle2'} sx={sliderTitle}>How much does the average person in your team bill?</Typography>
                      <Salary
                        // minMonth={0}
                        // maxMonth={250000}
                        // minYear={0}
                        // maxYear={3000000}
                        multiple={false}
                      // sliderProps={{
                      //   sx: [sliderStyles, { mb: 6, }],
                      //   marks: [
                      //     {
                      //       value: 0,
                      //       label: '0'
                      //     },
                      //     {
                      //       value: values.schedule === ApiTypes.SalaryPeriod.MONTH ? 250000 : 3000000,
                      //       label: maxFormatted,
                      //     }
                      //   ]
                      // }}
                      />
                    </Box>
                  </>
                }
                <FooterButton />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

const cardWrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [theme.breakpoints.down('sm')]: {
    gap: '16px'
  }
})

const sliderTitle = (theme: Theme) => ({
  marginBottom: '30px'
})

const validationSchema = Yup.object().shape({
  managedTeam: Yup.bool(),
  teammatesCount: Yup.number().max(100).min(0),
})

const sliderStyles = {
  width: '97%',
  '& .MuiSlider-markLabel': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    transform: 'none'
  },
  '.MuiSlider-markLabel[data-index="1"]': {
    left: 'auto !important',
    right: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '96%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}