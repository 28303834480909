import { useState } from 'react'
import { Box, Typography, Theme, Button, IconButton, Grid, Divider } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import UploadRoundedIcon from '@mui/icons-material/UploadRounded'
import { TypeOfComponent } from '../../pages/employee-profile-edit'
import Uploader, { Asset } from '../../components/Uploader/Uploader'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { authMutation } from '../../services/api'
import { buildAssetUrl } from '../../utilities/buildAssetUrl'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

interface Props {
  cv?: Asset
  type?: TypeOfComponent
  onCvUploaded?: (file: Asset) => void
  onCvDeleted?: (cv: Asset) => void
}

export const UploadCv = ({ type, onCvUploaded, cv, onCvDeleted }: Props) => {
  const [loading, setLoading] = useState(false)

  const onCvDelete = async () => {
    if (!cv) {
      return
    }

    setLoading(true)

    await authMutation({
      deleteAsset: [
        {
          data: {
            id: cv.id as string,
          },
        },
        true,
      ],
    })

    onCvDeleted && onCvDeleted(cv)

    setLoading(false)
  }

  const uploaderProps = {
    assets: [],
    uppy: {
      allowMultipleUploads: false,
    },
    async onFileUploaded(file: Asset) {
      if (onCvUploaded) {
        await onCvUploaded(file)
      }

      setLoading(false)
    },

    onBeforeFileUpload() {
      setLoading(true)
    },
  }

  if (type === TypeOfComponent.VIEW && !cv) {
    return null
  }

  return (
    <Box sx={wrapper}>
      <Box>
          <Typography sx={(theme) => ({
                    marginBottom: theme.spacing(3)
                  })} variant={'h2'}>
            CV
          </Typography>
          <Divider />
      </Box>
      <Grid container justifyContent='space-between' alignItems='center'>
        {/* <Grid item>
          <Typography variant={'h2'}>CV</Typography>
        </Grid> */}
        {type === TypeOfComponent.EDIT && !loading && !!cv && (
          <Grid item>
            <IconButton onClick={onCvDelete}>
              <DeleteRoundedIcon  sx={{ color: '#D32F2F', }}/>
            </IconButton>
          </Grid>
        )}
      </Grid>
      {type === TypeOfComponent.EDIT && !cv ? (
        <Uploader
          // type='button'
          // button={
          //   <LoadingButton
          //     loading={loading}
          //     size={'medium'}
          //     startIcon={!loading ? <UploadRoundedIcon /> : null}
          //     sx={buttonStyle}
          //     variant='contained'
          //   >
          //     Upload your CV
          //   </LoadingButton>
          // }
          {...uploaderProps}
        />
        
      ) : (
        <Button
          size='medium'
          startIcon={!loading && type === TypeOfComponent.EDIT ? <RemoveRedEyeIcon /> : null}
          sx={buttonStyle}
          variant='contained'
          onClick={() => {
            window.open(buildAssetUrl(cv), '_blank', 'noopener,noreferrer')
          }}
        >
          { type === TypeOfComponent.EDIT ?
            'View your CV' : 'View CV'
          }
        </Button>
      )}
    </Box>
  )
}

const wrapper = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  // backgroundColor: 'rgba(2, 136, 209, 0.12)',
  height: 'auto',
})

const buttonStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
  // mx: 'auto',
  margin: '0 auto 0 0',
})
